import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const ContactsForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [site, setSite] = useState("");
    const [company, setCompany] = useState("");
    const [wishPlan, setWishPlan] = useState("");
    const [message, setMessage] = useState("");
    const [wishMarketing, setWishMarketing] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sent = await fetch(`/api/requestDemo`, {
            method: "POST",
            body: JSON.stringify({
                name,
                email,
                site,
                company,
                wishPlan,
                wishMarketing,
                message
            })
        });

        alert("Takk for din søknad, vi tar kontakt med deg!");
    };

    return (
        <>
            <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
                <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
                    <h3 className='fw-medium h4'>
                        Be om tilgang til lukket Beta.
                    </h3>

                    <form action='#' className='mt-4 register-form'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='input-group mb-3'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Navn'
                                        aria-label='name'
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6 '>
                                <div className='input-group mb-3'>
                                    <input
                                        type='email'
                                        className='form-control'
                                        placeholder='Email'
                                        aria-label='email'
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='input-group mb-3'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Company website'
                                        aria-label='company-website'
                                        value={site}
                                        onChange={e => setSite(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='input-group mb-3'>
                                    <input
                                        type='email'
                                        className='form-control'
                                        placeholder='Selskap'
                                        aria-label='work-Email'
                                        value={company}
                                        onChange={e => setCompany(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12'>
                                <div className='input-group mb-3'>
                                    <select
                                        className='form-control form-select'
                                        name='budget'
                                        id='budget'
                                        required=''
                                        data-msg='Vennligst velg ønsket abonnement.'
                                        value={wishPlan}
                                        onChange={e => setWishPlan(e.target.value)}
                                    >
                                        <option value=''>Ønsket plan</option>
                                        <option value='oppstart'>Oppstart (&lt;50 pakker i mnd)</option>
                                        <option value='vekst'>Vekst (&lt;500 pakker i mnd)</option>
                                        <option value='storkunde'>Storkunde (&lt;1500 pakker i mnd)</option>
                                        <option value='enterprise'>Enterprise (&lt;2000+ pakker i mnd)</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='input-group mb-3'>
                  <textarea
                      className='form-control'
                      placeholder='Fortell oss litt om dere og hva dere er på utkikk etter!'
                      style={{minHeight: '120px'}}
                      rows={4}
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                  ></textarea>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='form-check'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        checked={wishMarketing}
                                        onChange={() => setWishMarketing(d => !d)}
                                        id='flexCheckChecked'
                                    />
                                    <label
                                        className='form-check-label small'
                                        htmlFor='flexCheckChecked'
                                    >
                                        Jeg vil gjerne motta markedsføring fra dere, og jeg kan avslutte dette
                                        abonnementet når som helst.
                                        <Link to='/personvern'>Se vår personvern-policy</Link>.
                                    </label>
                                </div>
                            </div>
                            <div className='col-12'>
                                <button
                                    type='submit'
                                    className='btn btn-primary mt-4 d-block w-100'
                                    onClick={handleSubmit}
                                >
                                    Send søknad
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactsForm;
