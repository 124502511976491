import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import CtaSubscribe from '../components/cta/CtaSubscribe';
import FaqTwo from '../components/faqs/FaqTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import PriceThree from "../components/prices/PriceThree";
import PriceTwo from "../components/prices/PriceTwo";

const Pricing = () => {
    return (
        <Layout>
            <PageMeta
                title='Dropper - Priser og abonnement - Fraktløsninger for nettbutikker'
                description="Se hvilke fraktpriser Dropper kan tilby deg og din bedrift, og få stordriftsfordeler med oss."
            />
            <Navbar/>
            <PageHeader
                title='Våre abonnement & priser'
                desc='Vi har flere ulike abonnement slik at du aldri trenger å betale mer enn du må basert på hvilke egenskaper du trenger for din nettbutikk. Du kan når som helst endre abonnement eller si opp.'
            />
            <PriceThree/>
            <PriceTwo/>
            <FaqTwo/>
            <CtaSubscribe/>
            <FooterOne
                style={{
                    background:
                        "url('assets/img/page-header-bg.svg')no-repeat bottom right",
                }}
            />
        </Layout>
    );
};

export default Pricing;
