import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";

const Navbar = ({navDark}) => {
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    useEffect(() => {
        const stickyheader = document.querySelector(".main-header");
        setHeaderTop(stickyheader.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    return (
        <>
            <header
                className={`main-header ${navDark ? "position-absolute " : ""
                } w-100 `}
            >
                <nav
                    className={`navbar navbar-expand-xl z-10 ${navDark ? "navbar-dark" : "navbar-light"
                    } sticky-header ${scroll > headerTop ? "affix" : ""}`}
                >
                    <div className="container d-flex align-items-center justify-content-lg-between position-relative">
                        <Link
                            to="/"
                            className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
                        >
                            <img
                                src="https://dropper.no/assets/logos/dropper-logo/PNGSVG/1x/Asset3.png"
                                alt="logo"
                                className="img-fluid logo-white"
                                style={{
                                    width: "50%"
                                }}
                            />
                            <img
                                src="https://dropper.no/assets/logos/dropper-logo/PNGSVG/1x/Asset1.png"
                                alt="logo"
                                className="img-fluid logo-color"
                                style={{
                                    width: "50%"
                                }}
                            />
                        </Link>

                        <Link
                            className="navbar-toggler position-absolute right-0 border-0"
                            to={process.env.PUBLIC_URL + "#offcanvasWithBackdrop"}
                            role="button"
                        >
              <span
                  className="far fa-bars"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBackdrop"
                  aria-controls="offcanvasWithBackdrop"
              ></span>
                        </Link>

                        <div className="collapse navbar-collapse justify-content-center">
                            <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link"
                                        to="/"
                                    >
                                        Hjem
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/priser"
                                        className="nav-link"
                                    >
                                        Fraktavtaler
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/abonnement"
                                        className="nav-link"
                                    >
                                        Abonnement
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/kontakt-oss"
                                        className="nav-link"
                                    >
                                        Kontakt oss
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="/#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Annet
                                    </a>
                                    <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                        <div className="dropdown-grid rounded-custom width-full">
                                            <div className="dropdown-grid-item">
                                                <h6 className="drop-heading">Nyttige linker</h6>
                                                <Link to="/kontakt-oss" className="dropdown-link px-0">
                          <span className="me-2">
                            <i className="far fa-address-book"></i>
                          </span>
                                                    <div className="drop-title">Kontakt Oss</div>
                                                </Link>
                                                <Link
                                                    to="/integrasjoner"
                                                    className="dropdown-link px-0"
                                                >
                          <span className="me-2">
                            <i className="far fa-server"></i>
                          </span>
                                                    <div className="drop-title">Våre integrasjoner</div>
                                                </Link>
                                                <Link to="/blogg" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-th-large"></i>
                          </span>
                                                    <div className="drop-title">Blogg</div>
                                                </Link>
                                            </div>
                                            <div className="dropdown-grid-item radius-right-side bg-light">
                                                <h6 className="drop-heading">Ressurser </h6>

                                                <a
                                                    href="https://help.dropper.no/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="dropdown-link"
                                                >
                          <span className="me-2">
                            <i className="far fa-life-ring"></i>
                          </span>
                                                    <div className="drop-title">Hjelpesenter</div>
                                                </a>
                                                <Link to="/kontakt-oss" className="dropdown-link disabled">
                          <span className="me-2">
                            <i className="far fa-laptop-code"></i>
                          </span>
                                                    <div className="drop-title">API Dokumentasjon</div>
                                                </Link>
                                                <Link to="/sikkerhet" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-shield"></i>
                          </span>
                                                    <div className="drop-title">Sikkerhet</div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                            <a
                                href="https://app.dropper.no/authentication/login"
                                className="btn btn-link text-decoration-none me-2"
                            >
                                Logg inn
                            </a>
                            <a
                                href="https://app.dropper.no/authentication/register"
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary"
                            >
                                Kom i gang
                            </a>
                        </div>
                        <OffCanvasMenu/>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Navbar;
