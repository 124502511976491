import React, {useState} from 'react';

const ContactFormTwo = () => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sent = await fetch(`/api/contact`, {
            method: "POST",
            body: JSON.stringify({
                name,
                company,
                email,
                phone,
                message
            })
        });

        alert("Takk for at du sendte oss en melding, vi tar kontakt med deg!");
    };
    return (
        <>
            <section
                className='contact-us-form pt-60 pb-120'
                style={{
                    background:
                        "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
                }}
            >
                <div className='container'>
                    <div className='row justify-content-lg-between align-items-center'>
                        <div className='col-lg-6 col-md-8'>
                            <div className='section-heading'>
                                <h2>Send oss en melding</h2>
                                <p>
                                    Du kan også enkelt ta kontakt med oss gjennom skjemaet under så kan vi komme tilbake
                                    til deg på dine
                                    foretrukne kommunikasjonsmetode.
                                </p>
                            </div>
                            <form action='#' className='register-form'>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <label htmlFor='navn' className='mb-1'>
                                            Navn <span className='text-danger'>*</span>
                                        </label>
                                        <div className='input-group mb-3'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id='navn'
                                                required
                                                placeholder='Navn'
                                                aria-label='Navn'
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-6 '>
                                        <label htmlFor='companyName' className='mb-1'>
                                            Selskap
                                        </label>
                                        <div className='input-group mb-3'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id='companyName'
                                                placeholder='Selskap'
                                                aria-label='Selskap'
                                                value={company}
                                                onChange={e => setCompany(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <label htmlFor='phone' className='mb-1'>
                                            Telefon <span className='text-danger'>*</span>
                                        </label>
                                        <div className='input-group mb-3'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id='phone'
                                                required
                                                placeholder='Telefon'
                                                aria-label='Phone'
                                                value={phone}
                                                onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <label htmlFor='email' className='mb-1'>
                                            E-post<span className='text-danger'>*</span>
                                        </label>
                                        <div className='input-group mb-3'>
                                            <input
                                                type='email'
                                                className='form-control'
                                                id='email'
                                                required
                                                placeholder='E-post'
                                                aria-label='Email'
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <label htmlFor='yourMessage' className='mb-1'>
                                            Din melding <span className='text-danger'>*</span>
                                        </label>
                                        <div className='input-group mb-3'>
                      <textarea
                          className='form-control'
                          id='yourMessage'
                          required
                          placeholder='Hva kan vi hjelpe deg med?'
                          style={{minHeight: '120px'}}
                          rows={4}
                          value={message}
                          onChange={e => setMessage(e.target.value)}
                      ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type='submit'
                                    className='btn btn-primary mt-4'
                                    onClick={handleSubmit}
                                >
                                    Send melding
                                </button>
                            </form>
                        </div>
                        <div className='col-lg-5 col-md-10'>
                            <div className='contact-us-img'>
                                <img
                                    src='assets/img/contact-us-img-2.svg'
                                    alt='contact us'
                                    className='img-fluid'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactFormTwo;
