import React from 'react';

const ContactBox = () => {
    return (
        <>
            <section className='contact-promo ptb-120'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
                            <div
                                className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                                <span className='fad fa-comment-alt-lines fa-3x text-primary'></span>
                                <div className='contact-promo-info mb-4'>
                                    <h5>Chat med oss</h5>
                                    <p>
                                        Dessverre på grunn av kapasitet har vi ikke mulighet til å besvare chat for
                                        øyeblikket, men anbefaler sterkt at du kontakter oss på e-post eller telefon.
                                    </p>
                                </div>
                                <a
                                    href='mailto:hellothemetags@gmail.com'
                                    className='btn btn-primary mt-auto disabled'
                                >
                                    <i className="fad fa-x"/>
                                    {" "}Chat with us
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
                            <div
                                className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                                <span className='fad fa-envelope fa-3x text-primary'></span>
                                <div className='contact-promo-info mb-4'>
                                    <h5>Send en e-post</h5>
                                    <p>
                                        Send oss en e-post til{' '}
                                        <strong>salg@dropper.no</strong>
                                        <hr/>
                                        Vi svarer som oftest innen en time.
                                    </p>
                                </div>
                                <a
                                    href='mailto:hellothemetags@gmail.com'
                                    className='btn btn-primary mt-auto'
                                >
                                    Send e-post
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
                            <div
                                className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                                <span className='fad fa-phone fa-3x text-primary'></span>
                                <div className='contact-promo-info mb-4'>
                                    <h5>Ring oss</h5>
                                    <p>
                                        Du kan enkelt ta kontakt med oss på telefon{' '}
                                        <strong>hverdager 08:00 - 14:00 </strong>
                                    </p>
                                </div>
                                <a href='tel:+4794444881' className='btn btn-primary mt-auto'>
                                    +47 94 44 48 81
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactBox;
