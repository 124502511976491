import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqTwo = () => {
    return (
        <>
            <section className='faq-section ptb-120 bg-light'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-7 col-12'>
                            <SectionTitle
                                subtitle='FAQ'
                                title='Ofte stilte spørsmål'
                                description={`Du har helt sikkert noen spørsmål før du kommer i gang med å bruke vår tjeneste, vi har prøvd å svare på de fleste & viktigste her.`}
                                centerAlign/>

                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-lg-7 col-12'>
                            <div className='accordion faq-accordion' id='accordionExample'>
                                <div className='accordion-item border border-2 active'>
                                    <h5 className='accordion-header' id='faq-0'>
                                        <button
                                            className='accordion-button'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapse-0'
                                            aria-expanded='true'
                                        >
                                            Er det bindingstid?
                                        </button>
                                    </h5>
                                    <div
                                        id='collapse-0'
                                        className='accordion-collapse collapse show'
                                        aria-labelledby='faq-0'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body'>
                                            <b>Nei, ingen bindingstid.</b> Vi vet at vårt system og plattform er et av
                                            de beste på markedet, og føler oss ikke presset til å tvinge kundene våre
                                            til
                                            å inngå langtidsavtaler.
                                            <br/>
                                            <hr/>
                                            Vi tilbyr rabatterte priser til kunder som velger å betale årlig, dette er
                                            den
                                            eneste bindingen vi har. Velger du månedlig abonnement medfører det forhøyde
                                            operasjonelle
                                            kostnader, og dette reflekteres også i prisen - men du har ingen binding og
                                            kan avslutte når og hvor som helst.
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item border border-2 active'>
                                    <h5 className='accordion-header' id='faq-1'>
                                        <button
                                            className='accordion-button'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapse-1'
                                            aria-expanded='true'
                                        >
                                            Koster det penger å bruke dere?
                                        </button>
                                    </h5>
                                    <div
                                        id='collapse-1'
                                        className='accordion-collapse collapse show'
                                        aria-labelledby='faq-1'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body'>
                                            <b>Ja, det gjør det.</b> Likevel, vi tilbyr en gratis prøveperiode i
                                            <b>to måneder</b> for alle våres abonnement(med unntak av Enterprise).
                                            <br/>
                                            <hr/>
                                            Vi har fokusert veldig på å betydelig forbedre opplevelsen for de som
                                            driver innenfor logistikk, og har derfor gjort operasjonelle velg for å
                                            forbedre ytelsen, men det
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item border border-2'>
                                    <h5 className='accordion-header' id='faq-2'>
                                        <button
                                            className='accordion-button collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapse-2'
                                            aria-expanded='false'
                                        >
                                            Kan jeg endre abonnementet?
                                        </button>
                                    </h5>
                                    <div
                                        id='collapse-2'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='faq-2'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body'>
                                            <b>Ja, det kan du.</b> Vi i Dropper er opptatt av at abonnementet ditt skal
                                            passe din drift, og du skal enkelt
                                            kunne stoppe, oppgradere eller nedgradere abonnementet når- og hvor som
                                            helst. Vi tar ingen gebyrer
                                            når du gjør endringer på ditt abonnement*.
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item border border-2'>
                                    <h5 className='accordion-header' id='faq-3'>
                                        <button
                                            className='accordion-button collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapse-3'
                                            aria-expanded='false'
                                        >
                                            Kan jeg få tilbake pengene for abonnementet?
                                        </button>
                                    </h5>
                                    <div
                                        id='collapse-3'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='faq-3'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body'>
                                            Dessverre tilbyr vi ikke denne løsningen som standard da vi har visse
                                            operasjonelle kostnader
                                            med å håndtere ditt abonnement. Vi anbefaler at du velger det abonnementet
                                            du tror passer deg best,
                                            og oppgraderer når du er sikker på at du vil være innenfor de nivåene og
                                            kriteriene med din drift.
                                        </div>
                                    </div>
                                </div>

                                <div className='accordion-item border border-2'>
                                    <h5 className='accordion-header' id='faq-4'>
                                        <button
                                            className='accordion-button collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#collapse-4'
                                            aria-expanded='false'
                                        >
                                            Jeg ønsker å bruke dere men har noen behov, hva gjør jeg?
                                        </button>
                                    </h5>
                                    <div
                                        id='collapse-4'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='faq-4'
                                        data-bs-parent='#accordionExample'
                                    >
                                        <div className='accordion-body'>
                                            Vi i Dropper ønsker å alltid være så fremtidsrettet som mulig, og dersom du
                                            ønsker å komme i gang med vår tjeneste, men ser at det er noe som mangler -
                                            som du
                                            kunne tenkt deg å være på plass først - kan du alltid ta kontakt med oss.
                                            <br/>
                                            <br/>
                                            Vi har in-house utviklere som gjerne integrerer det meste på kort tid om det
                                            er behov og ønske fra dette.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FaqTwo;
