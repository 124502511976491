import React from 'react';
import {CARRIER_INTEGRATIONS} from "./IntegrationList";
import SectionTitle from "../common/SectionTitle";

const CarrierIntegrationsList = () => {
    return (
        <>
            <section className='our-integration bg-light pb-60'>
                <SectionTitle
                    title="Transportører"
                    description="Se hvilke transportører vi støtter - som du kan enkelt komme i gang med!"
                    centerAlign
                />
                <div className='container'>
                    <div className='position-relative w-100'>
                        <div className='row justify-content-center'>
                            {
                                CARRIER_INTEGRATIONS.map(integration => (
                                    <div className='col-lg-3 col-md-5 h-auto w-25'>
                                        <div
                                            className='position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5 text-center'
                                        >
                                            <div className='position-relative connected-app-content'>
                                                <div
                                                    className="integration-logo bg-white rounded-3 shadow-sm p-2 d-inline-block">
                                                    <img src={integration.logo} height={40} alt="integration"
                                                         className=""/>
                                                </div>
                                                <h5>
                                                    <b>
                                                        {
                                                            integration.title
                                                        }
                                                    </b>
                                                </h5>
                                                <p className='mb-0 text-muted'>
                                                    {
                                                        integration.description
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CarrierIntegrationsList;
