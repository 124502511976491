const SHOP_INTEGRATIONS = [
    {
        title: "Shopify",
        value: "shopify",
        description: "Shopify er en av markedets ledende når det kommer til oppstart av nettbutikker uavhengig av størrelse.",
        logo: "/assets/logos/shopify_logo.png"
    },
    {
        title: "WooCommerce",
        value: "woocommerce",
        description: "WooCommerce er en av verdens mest kjente og mest brukte nettbutikk-løsninger bygget på WordPress.",
        logo: "/assets/logos/woocommerce.png"
    },
    {
        title: "MyStore",
        value: "mystore",
        description: "MyStore er en av de mest kjente nettbutikk-løsningene i norden, og har fått veldig mye popularitet i det siste.",
        logo: "/assets/logos/mystore.png"
    },
    {
        title: "Quickbutik",
        value: "quickbutik",
        description: "Quickbutik er en startup opprettet i Sverige som på kort tid har fått tak i flere og flere norske butikker.",
        logo: "/assets/logos/quickbutik.svg"
    },
    {
        title: "BigCommerce",
        value: "bigcommerce",
        description: "BigCommerce er en større nettbutikk-plattform som har begynt å ta opp kampen mot Shopify.",
        logo: "/assets/logos/bigcommerce.webp"
    },
    {
        title: "SquareUp",
        value: "squareup",
        description: "SquareUp er en moderne plattform for å enkelt opprette nettbutikker som ser veldig bra ut.",
        logo: "/assets/logos/SquareUp_logo.png"
    }
];

const CARRIER_INTEGRATIONS = [
    {
        title: "PostNord Norge",
        value: "postnord_no",
        description: "PostNord",
        logo: "/assets/logos/carriers/postnord-logo.png"
    },
    {
        title: "Bring Norge",
        value: "bring_no",
        description: "Bring",
        logo: "/assets/logos/carriers/bring-logo.png"
    },
    {
        title: "HeltHjem",
        value: "helthjem",
        description: "Helthjem",
        logo: "/assets/logos/helthjem.svg"
    }
];

export {
    SHOP_INTEGRATIONS,
    CARRIER_INTEGRATIONS
}
