import React from 'react';
import {Link} from "react-router-dom";

const CtaSubscribe = () => {
    return (
        <>
            <section className='cta-subscribe bg-light ptb-120 position-relative overflow-hidden'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-7 col-md-8'>
                            <div className='subscribe-info-wrap text-center position-relative z-2'>
                                <div className='section-heading'>
                                    <h4 className='h5 text-primary'>
                                        Interessant?
                                    </h4>
                                    <h2>Start en gratis prøveperiode</h2>
                                    <p>
                                        Få sjansen til å se hvordan Dropper fungerer, og sjekk om det passer til din
                                        bedrift i dag, helt uten kostnad.
                                    </p>
                                </div>
                                <div className='form-block-banner mw-60 m-auto mt-5 row'>
                                    <div className="col-6">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href='https://app.dropper.no/authentication/register'
                                            className='btn btn-primary'
                                        >
                                            <i className='fal fa-arrow-right'/>
                                            {" "} Kom i gang nå
                                        </a>
                                    </div>
                                    <div className="col-6">
                                        <Link to='/kontakt-oss' className='ml-2 pl-2 btn btn-success'>
                                            <i className='fal fa-phone'/>
                                            {" "}Kontakt oss
                                        </Link>
                                    </div>
                                </div>
                                <ul className='nav justify-content-center subscribe-feature-list mt-3'>
                                    <li className='nav-item'>
                    <span>
                      <i className='fad fa-dot-circle text-primary me-2'></i>
                      Gratis prøveperiode
                    </span>
                                    </li>
                                    <li className='nav-item'>
                    <span>
                      <i className='fad fa-dot-circle text-primary me-2'></i>
                      Gode fraktpriser
                    </span>
                                    </li>
                                    <li className='nav-item'>
                    <span>
                      <i className='fad fa-dot-circle text-primary me-2'></i>
                      God kundeservice
                    </span>
                                    </li>
                                    <li className='nav-item'>
                    <span>
                      <i className='fad fa-dot-circle text-primary me-2'></i>
                      Ingen bindingstid
                    </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        className='bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5'></div>
                    <div className='bg-circle rounded-circle circle-shape-1 position-absolute bg-warning right-5'></div>
                </div>
            </section>
        </>
    );
};

export default CtaSubscribe;
