import Routes from "./routers/Routes";
import posthog from "posthog-js";
import "./styles/misc-animations.css";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

//Modal Video
import "react-modal-video/scss/modal-video.scss";
import {useEffect} from "react";

const App = () => {

    useEffect(() => {
        posthog.init("phc_gMlC7HQxpN5NDqnGgVb2gv6OGhhhSNtybE7ZAn054AU", {
            api_host: "https://eu.posthog.com"
        });
    }, []);

    return (
        <>
            <Routes/>
        </>
    );
};

export default App;
