import React from 'react';
import {Link} from 'react-router-dom';

const FooterOne = ({footerLight, style, footerGradient}) => {
    return (
        <>
            <footer className='footer-section'>
                <div
                    className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
                        footerGradient ? 'bg-gradient' : ''
                    }  text-white ptb-120`}
                    style={style}
                >
                    <div className='container'>
                        <div className='row justify-content-between'>
                            <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                                <div className='footer-single-col'>
                                    <div className='footer-single-col mb-4'>
                                        <img
                                            src='/assets/logos/dropper-logo/PNGSVG/1x/Asset3.png'
                                            style={{
                                                width: "50%"
                                            }}
                                            alt='logo'
                                            className='img-fluid logo-white'
                                        />
                                        <img
                                            src='/assets/logos/dropper-logo/PNGSVG/1x/Asset1.png'
                                            style={{
                                                width: "50%"
                                            }}
                                            alt='logo'
                                            className='img-fluid logo-color'
                                        />
                                    </div>
                                    <p>
                                        Dropper er en plattform å for enkelt håndtere & innfri ordrer og å sende pakker
                                        med gode fraktpriser i Norge og utlandet.
                                    </p>
                                    <p>
                                        Org.nr: 927 661 411
                                    </p>

                                    <hr/>

                                    <div>
                                        <p>
                                            <b>E-post: </b>salg@dropper.no
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                                <div className='row'>
                                    <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                                        <div className='footer-single-col'>
                                            <h3>Primærsider</h3>
                                            <ul className='list-unstyled footer-nav-list mb-lg-0'>
                                                <li>
                                                    <Link to='/' className='text-decoration-none'>
                                                        Hjem
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/kontakt-oss' className='text-decoration-none'>
                                                        Våre tjenester
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/services' className='text-decoration-none'>
                                                        Våre priser
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/integrasjoner' className='text-decoration-none'>
                                                        Våre integrasjoner
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                                        <div className='footer-single-col'>
                                            <h3>Om oss</h3>
                                            <ul className='list-unstyled footer-nav-list mb-lg-0'>
                                                <li>
                                                    <Link to='/pricing' className='text-decoration-none'>
                                                        Om oss
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to='/blogs' className='text-decoration-none'>
                                                        Blogg
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to='/blog-single'
                                                        className='text-decoration-none'
                                                    >
                                                        Kontakt oss
                                                    </Link>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://help.dropper.no/"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className='text-decoration-none'
                                                    >
                                                        Hjelpesenter
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                                        <div className='footer-single-col'>
                                            <h3>Annet</h3>
                                            <ul className='list-unstyled footer-nav-list mb-lg-0'>
                                                <li>
                                                    <Link
                                                        to='/vilkar'
                                                        className='text-decoration-none'
                                                    >
                                                        Vilkår for bruk
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to='/personvern'
                                                        className='text-decoration-none'
                                                    >
                                                        Personvern
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to='/angrerett'
                                                        className='text-decoration-none'
                                                    >
                                                        Angrerett
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to='/sikkerhet'
                                                        className='text-decoration-none'
                                                    >
                                                        Sikkerhet
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`footer-bottom ${
                        footerLight ? 'footer-light' : 'bg-dark'
                    } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
                >
                    <div className='container'>
                        <div className='row justify-content-between align-items-center'>
                            <div className='col-md-7 col-lg-7'>
                                <div className='copyright-text'>
                                    <p className='mb-lg-0 mb-md-0'>
                                        &copy; Dropper AS - alle rettigheter reservert.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default FooterOne;
