import React, {useState} from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureOne = () => {
    const [h1, sh1] = useState(false);
    const [h2, sh2] = useState(false);
    const [h3, sh3] = useState(false);

    return (
        <>
            <section className='feature-promo ptb-120 bg-light'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6 col-md-10'>
                            <SectionTitle
                                title='Alle fordelene du trenger'
                                description='Skap bedre effektivitet på lageret og spar mer penger ved å bytte til Dropper.'
                                centerAlign
                            />
                        </div>
                    </div>
                    <div
                        className='row justify-content-around'
                        style={{
                            minHeight: "20vh"
                        }}
                    >
                        <div
                            className={`col-lg-4 col-md-6`}
                            onMouseEnter={() => sh3(true)}
                            onMouseLeave={() => sh3(false)}
                        >
                            <div
                                className='position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5 text-center'>
                                <div className='feature-icon icon-center pb-5 rounded-custom'>
                                    <i className='fad fa-code text-primary fa-3x'></i>
                                </div>
                                <div className='feature-info-wrap'>
                                    <h3 className='h5'>Gunstige fraktavtaler</h3>
                                    <p>
                                        Dropper har fremforhandlet gode frakt-avtaler med de ledende transportørene.

                                        // TODO: TBD
                                        deg{' '}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`col-lg-4 col-md-6`}
                            onMouseEnter={() => !h1 ? sh1(true) : {}}
                            onMouseLeave={() => sh1(false)}
                        >
                            <div
                                className='position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5 text-center'>
                                <div className='feature-icon icon-center pb-5 rounded-custom'>
                                    <i className='fad fa-gauge-high fa-3x text-primary'></i>
                                </div>
                                <div className='feature-info-wrap'>
                                    <h3 className='h5'>Lynrask teknologi</h3>
                                    <p>
                                        Dropper er utviklet med den nyeste teknologien som sørger for at man
                                        slipper å vente århundre på at ting skal laste inn og fungere.{' '}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`col-lg-4 col-md-6`}
                            onMouseEnter={() => sh2(true)}
                            onMouseLeave={() => sh2(false)}
                        >
                            <div
                                className='position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5 text-center'>
                                <div className='feature-icon icon-center pb-5 rounded-custom'>
                                    <i className='fad fa-desktop text-primary fa-3x'></i>
                                </div>
                                <div className='feature-info-wrap'>
                                    <h3 className='h5'>God oversikt, hele tiden</h3>
                                    <p>
                                        Bestill frakt, print pakkesedler, send SMS & e-post og mye mer fra én og samme
                                        plass.
                                        Dropper analyserer og sporer alle ordrene for å gi deg maksimal oversikt.{' '}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-60'>
                        <div className='col-lg-6 mt-4'>
                            <div
                                className='position-relative d-flex flex-column h-100 flex-wrap bg-success-light p-5 rounded-custom'>
                                <div className='cta-left-info mb-2'>
                                    <h5>Super-enkelt å komme i gang!</h5>
                                    <p>
                                        Start en gratis prøveperiode og koble Dropper med din nettbutikk på under
                                        2 minutter. Vi støtter de aller fleste plattformene.{' '}
                                    </p>
                                </div>
                                <div className='action-btns mt-auto'>
                                    <a
                                        href="https://app.dropper.no/authentication/register"
                                        target="_blank"
                                        rel="noreferrer"
                                        className='btn btn-primary btn-sm'
                                    >
                                        Kom i gang
                                    </a>
                                </div>
                                <div className='cta-img position-absolute right-0 bottom-0'>
                                    <img
                                        src='assets/img/cta-img-1.png'
                                        alt='cta'
                                        className='img-fluid'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 mt-4'>
                            <div
                                className='position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom'>
                                <div className='cta-left-info mb-2'>
                                    <h5>Muligheter for API-integrasjon</h5>
                                    <p>
                                        Dropper sjekker jevnlig ordrer og forsendelser for avvik og kan automatisk
                                        varsle deg og kundene dine ved forsinkelser. {' '}
                                    </p>
                                </div>
                                <div className='action-btns mt-auto'>
                                    <a
                                        href="https://docs.dropper.no/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className='btn btn-outline-dark btn-sm'
                                    >
                                        Se API-dokumentasjon
                                    </a>
                                </div>
                                <div className='cta-img position-absolute right-0 bottom-0'>
                                    <img
                                        src='assets/img/cta-img-2.png'
                                        alt='cta img'
                                        className='img-fluid'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureOne;
