import React, {Component} from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroOne from './HeroOne';
import FeatureImgContentFour from '../../components/feature-img-content/FeatureImgContentFour';
import FeatureImg from '../../components/features/FeatureImg';
import PageMeta from '../../components/common/PageMeta';
import WorkProcess from '../../components/work-process/WorkProcess';
import FaqOne from '../../components/faqs/FaqOne';
import IntegrationOne from '../../components/integration/IntegrationOne';
import FooterOne from '../../layout/Footer/FooterOne';
import FeatureOne from '../../components/features/FeatureOne';
import GradientDiv from "../../components/GradientDiv";
import CtaTwo from "../../components/cta/CtaTwo";
import FeatureShippingRates from "../../components/features/FeatureShippingRates";

class HomeSassOne extends Component {
    render() {
        return (
            <Layout>
                <PageMeta title='Hjem - Dropper'/>
                <Navbar navDark/>
                <HeroOne/>
                <FeatureOne/>
                <FeatureImgContentFour/>
                <div className="container mb-3">
                    <GradientDiv height="1px"/>
                </div>
                <FeatureImg/>
                <FeatureShippingRates/>
                {/*<TestimonialOne darkBg/>*/}
                <WorkProcess/>
                <FaqOne/>
                <IntegrationOne/>
                <CtaTwo/>
                <div>
                    <GradientDiv height="5px"/>
                </div>
                <FooterOne footerLight/>
            </Layout>
        );
    }
}

export default HomeSassOne;
