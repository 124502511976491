import React from 'react';
import SectionTitle from '../common/SectionTitle';

const SecurityPointOne = ({paddingTop}) => {
    return (
        <>
            <section
                className={`feature-section ${
                    paddingTop ? 'ptb-120' : 'pt-60 pb-120'
                } `}
            >
                <div className='container'>
                    <div className='row align-items-lg-center justify-content-between'>
                        <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
                            <div className='mb-4' data-aos='fade-up'>
                                <SectionTitle
                                    title='Sikkerhet bygget inn i hele infrastrukturen.'
                                    description='Dropper var laget med et sterkt fokus på personvern & sikkerhet, for å skape mer
                                    tillit til både våre kunder, men også dine kunder.'
                                />
                            </div>
                            <ul
                                className='list-unstyled d-flex flex-wrap list-two-col mt-5'
                                data-aos='fade-up'
                                data-aos-delay='50'
                            >
                                <li>
                  <span className='d-block mb-4'>
                    <i className='fal fa-user fa-2x text-primary'></i>
                  </span>
                                    <h3 className='h5'>Personvern</h3>
                                    <p>
                                        Dine data er dine data, vi selger ikke og vil aldri selge data til 3. parter.
                                    </p>
                                </li>
                                <li>
                  <span className='d-block mb-4'>
                    <i className='fal fa-shield-keyhole fa-2x text-primary'></i>
                  </span>
                                    <h3 className='h5'>Kryptering</h3>
                                    <p>
                                        All data er kryptert under lagring og under transport frem og tilbake mellom
                                        servere.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className='col-lg-6 order-lg-1'>
                            <div className='pr-lg-4 mt-md-4 position-relative'>
                                <div
                                    className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'
                                    data-aos='fade-up'
                                    data-aos-delay='50'
                                >
                                    <img
                                        src='assets/icons/undraw_security.svg'
                                        alt=''
                                        className='img-fluid'
                                    />
                                    <div
                                        className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SecurityPointOne;
