import React from 'react';

const JobHeader = () => {
    return (
        <>
            <section
                className='page-header position-relative overflow-hidden ptb-120 bg-dark'
                style={{
                    background:
                        "url('assets/img/page-header-bg.svg')no-repeat bottom left",
                }}
            >
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-8 col-12'>
                        </div>
                    </div>

                    <div
                        className='bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5'></div>
                </div>
            </section>
        </>
    );
};

export default JobHeader;
