import React, {Component} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomeSassOne from "../themes/index1/HomeSassOne";
import SecurityPoints from "../pages/services/SecurityPoints";
import NotFoundScreen from "../components/others/NotFoundScreen";
import Pricing from "../pages/Pricing";
import ScrollToTop from "../components/common/ScrollToTop";
import Contact from "../pages/Contact";
import ComingSoon from "../pages/ComingSoon";
import Integrations from "../pages/integration/Integrations";
import IntegrationSingle from "../pages/integration/IntegrationSingle";
import RequestDemo from "../pages/RequestDemo";
import SingleProduct from "../pages/product/SingleProduct";
import SinglePortfolio from "../pages/SinglePortfolio";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import SubProcessors from "../SubProcessors";


/*
<Route exact path="/blogs" component={Blogs}/>
                        <Route exact path="/blog-single" component={SingleBlog}/>
                        <Route exact path="/career" component={Career}/>
                        <Route exact path="/career-single" component={CareerSingle}/>
                        <Route exact path="/help-center" component={HelpCenter}/>
                        <Route
                            exact
                            path="/help-center-single"
                            component={SingleHelpCenter}
                        />
                        <Route exact path="/password-reset" component={PasswordReset}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/signup" component={Signup}/>
*/
class Routes extends Component {
    render() {
        return (
            <>
                <Router>
                    <ScrollToTop/>
                    <Switch>
                        <Route exact path="/" component={HomeSassOne}/>
                        <Route exact path="/single-product" component={SingleProduct}/>
                        <Route exact path="/portfolio-single" component={SinglePortfolio}/>
                        {/*<Route exact path="/egenskaper" component={Services}/>*/}
                        <Route exact path="/sikkerhet" component={SecurityPoints}/>
                        <Route exact path="/kontakt-oss" component={Contact}/>
                        <Route exact path="/priser" component={Pricing}/>
                        <Route exact path="/request-for-demo" component={RequestDemo}/>
                        <Route exact path="/coming-soon" component={ComingSoon}/>
                        <Route exact path="/integrasjoner" component={Integrations}/>
                        <Route
                            exact
                            path="/integration-single"
                            component={IntegrationSingle}
                        />
                        <Route
                            exact
                            path="/vilkar"
                            component={Terms}
                        />
                        <Route
                            exact
                            path="/personvern"
                            component={Privacy}
                        />
                        <Route
                            exact
                            path="/underdatabehandlere"
                            component={SubProcessors}
                        />
                        <Route component={NotFoundScreen}/>
                    </Switch>
                </Router>
            </>
        );
    }
}

export default Routes;
