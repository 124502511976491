import React from 'react';
import PageMeta from '../components/common/PageMeta';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const Terms = () => {
    return (<Layout>
        <PageMeta title='Dropper - Vilkår for bruk - Fraktløsninger for bedrifter'/>
        <Navbar classOption='navbar-light'/>
        <div
            className="bg-white  mt-10 pt-10 container"
            style={{
                minHeight: "50vh"
            }}
        >
            <div className="text-center">
                <h1 className="h1 mt-100 fw-semi-bold">Vilkår for bruk</h1>
            </div>

            {/* Innledning Start*/}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">Innledning</h3>
                <p className="lead">
                    Takk for at du besøker Dropper! Vi er glad for at du er her! Vi ber om at du nå videre leser
                    vilkårene
                    for bruk av vår tjeneste - før du bruker vår tjeneste. På denne måten er vi begge innforståtte med
                    de vilkårene som er fremsatt av oss, hva vi forventer, hva vi tolerer, ikke tolerer og så videre.
                    Som med alt annet ber vi om at du bruker sunn fornuft når du besøker vår nettside og benytter deg
                    av våre tjenester.

                    <br/>
                    <br/>
                    Vi har prøvd å lage vilkårene så enkle å forstå som mulig, slik at det ikke oppstår misforståelse.
                    Skulle du ha spørsmål angående våre vilkår kan du alltid ta kontakt med oss.
                </p>
            </div>
            {/* Innledning Slutt*/}

            {/* 1. Generelt start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">1. Generelt</h3>
                <p className="lead">
                    Disse vilkårene definerer og regulerer forholdet mellom deg ("bruker") og Dropper AS ("oss" /
                    "selskapet" / "vår" / "Dropper").
                    Ved å opprette en konto på vår tjeneste Dropper eller andre tjenester som er tilknyttede vårt
                    selskap
                    bekrefter du at du har gjennomgått og godtatt våre til enhver tid gjeldende vilkår.
                </p>

                <p className="lead">
                    Dropper er en software-basert tjeneste som tilbyr en enkel - samt avansert - mulighet for å
                    administrere bestillinger,
                    ordne frakt til bestillingene og spore pakker som fører til en god oversikt for de som selger på
                    sine nettbutikker.
                    Dropper er beregenet for bedriftskunder, og det vil dermed være de alminnelige avtale- og
                    kjøpsvilkårene som gjelder
                    i eventuelle tvister.
                </p>

                <p className="lead">
                    For å bruke våre tjenester og plattform krever vi at alle brukere er 16 år eller eldre, har gyldig
                    norsk personnummer, og har forøvrig rett til å representere selskapene de registrerer seg opp for.
                    Vi tillater bare én hovedkonto per selskap, men det kan alltid lages "underbrukere" for alle selskap
                    og
                    hovedkontoer. Hovedkontoen til selskapet som er registrert på Dropper skal under enhver tid alltid
                    ha rettigheter til å handle på vegne av selskapet, for eksempel daglig leder.
                    Én person kan ha flere hovedkontoer så lenge hver hovedkonto tilhører et juridisk forskjellig
                    selskap.
                </p>
            </div>
            {/* 1. Generelt slutt */}

            {/* 2. Denne avtalen start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">2. Denne avtalen</h3>
                <h4 className="h4 mt-20">2.1 Effekt</h4>
                <p className="lead">
                    Når du godtar disse vilkårene og denne avtalen vil det anses som en juridisk bindende avtale mellom
                    deg, selskapet du representerer, og Dropper gyldig fra datoen du godtok vilkårene på.
                </p>

                <h4 className="h4 mt-20">2.2 Endringer</h4>
                <p className="lead">
                    Dropper forbeholder seg retten til å når som helst, uten forvarsel, endre sine nettsider, tjenester,
                    priser og vilkår, som kan skje
                    som følge av endringer i bransjen, styremøte, eller grunnet generelle endringer. Dropper skal ved
                    endringer oppdatere deg som kunde om dette, og hvilke endringer som er gjennomført. Ved oppdatering
                    plikter du deg som kunde til å gå gjennom oppdateringene.
                </p>
            </div>
            {/* 2. Denne avtalen slutt */}

            {/* 3. Dine forpliktelser start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">3. Dine forpliktelser</h3>
                <p className="lead">
                    Du som kunde forplikter deg til å alltid holde din konto og lagre dine brukernavn/passord på
                    forsvarlig måte.
                    Ved eventuell uautorisert tilgang vil det alltid regnes deg som kunde sitt ansvar, og du vil bli
                    holdt
                    ansvarlig for alle kostnader assosiert med slike tilfeller. Dropper vil alltid gjøre sitt ytterste
                    for å sørge for at uvedkommende ikke får tilgang til din konto. Dropper kan og vil ved mistenkelige
                    innloggingsforsøk gi den mistenkte uvedkommende flere utfordringer for å være mer sikker på at
                    vedkommende som prøver å logge seg på din konto er den faktiske kontoeieren.
                    <br/>
                    <br/>
                    Anbefalte sikkerhetstiltak for deg som kunde er å alltid sørge for at ditt brukernavn og passord er
                    lagret på en trygg måte, for eksempel i en "Password Manager", og aktivere to-trinns-verifikasjon
                    (2FA) som forsterker kontosikkerheten. Hvordan du som kunde velger å sikre deg og din konto er opp
                    til
                    deg, men uansett vil all uautorisert tilgang regnes som ditt ansvar.
                </p>
            </div>
            {/* 3. Dine forpliktelser slutt */}

            {/* 4. Verneting og lovvalg start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">4. Verneting og lovvalg</h3>
                <p className="lead">
                    Vilkårene for bruk er underlagt norsk rett. Tvister angående tolking og anvendelse av vilkårene
                    skal avgjøres av norske domstoler. Ved eventuelle tvister er standard domstol Sogn og Fjordane
                    tingrett.
                </p>
            </div>
            {/* 4. Verneting og lovvalg slutt */}

            {/* 5. Kontakt start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">5. Kontakt</h3>
                <p className="lead">
                    Vi er i denne avtalen:
                    <br/>
                    <br/>
                    <b>Dropper AS</b>
                    <br/>
                    Gamlestøylsvegen 26
                    <br/>
                    6814 Førde
                    <br/>
                    Vestland
                    <br/>
                    Norge
                    <br/>
                    Org.nr. 927 661 411
                </p>
            </div>
            {/* 5. Kontakt slutt */}

            {/* 6. Dropper sin tjeneste start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">6. Dropper sin tjeneste</h3>
                <h4 className="h4 mt-20">6.1 Hvem kan bruke Dropper?</h4>
                <p className="lead">
                    Dropper tilbyr en tjeneste ekslusivt til bedrifter, altså vil ikke privatpersoner kunne registrere
                    seg på vår tjeneste, og kan heller ikke lage forsendelser eller brukere. Forsendelser som er
                    registrert
                    gjennom Dropper er bare for egen distribusjon og skal ikke selges videre eller benyttes av andre enn
                    deg som kunde.
                </p>
                <h4 className="h4 mt-20">6.2 Aksept av transportørene sine vilkår</h4>
                <p className="lead">
                    Dropper er bare en tilbyder som gir tilgang til registrerte kunder slik at de enkelt kan opprette
                    frakt
                    med de forskjellige transportselskapene. Dropper er ikke en spedisjonsagent, transportør eller
                    lignende,
                    og håndterer ikke fysisk de forsendelsene som går ut. Du som kunde forplikter deg til å lese hver
                    enkelt
                    transportør sine vilkår, begrensninger og lignende informasjon, og med å godta denne avtalen, er du
                    innforstått med at Dropper ikke skal holdes ansvarlig for forsendelsene på noen måte.

                    <br/>
                    <br/>

                    Du aksepterer også at du må følge kravene som er oppgitt av de forskjellige transportørene, som for
                    eksempel:

                    <ul style={{marginLeft: "50px"}}>
                        <li>Krav til innpakking</li>
                        <li>Krav til relevant merking av pakke</li>
                        <li>Reklamasjonshåndtering</li>
                        <li>Diverse gebyrer og spesial frakthåndterings-gebyr</li>
                        <li>Anskaffelse av egen forsikring på forsendelser</li>
                    </ul>

                    <br/>

                    I de tilfeller der det er konflikter mellom Dropper og transportøren sine vilkår, er det Dropper
                    sine vilkår som overgår.
                </p>

                <h4 className="h4 mt-20">6.3 Spørsmål angående frakt, levering og prosesser</h4>
                <p className="lead">
                    Dropper er ikke en transportør siden vi ikke håndterer pakkene fysisk. Skulle du ha spørsmål om en
                    forsendelse
                    må du dermed ta kontakt direkte med transportør for spørsmål om for eksempel: reklamasjon,
                    leveringstid,
                    hentesteder, vilkår.

                    <br/>
                    <br/>
                    Dropper skal være behjelpelige og kan også ved anmodning fra deg som kunde sette deg i kontakt med
                    de ulike transportørene.
                </p>

                <h4 className="h4 mt-20">6.4 Web tjenester</h4>
                <p className="lead">
                    Dropper tilbyr et stort utvalg av tjenester gratis til sine registrerte kunder, som for eksempel
                    API,
                    prissjekk, og tilsvarende innenfor industrien. Vilkårene for at du kan bruke disse tjenestene er at
                    du
                    lager fraktetiketter gjennom Dropper, så lenge det er snakk om at vi tilbyr den transportøren du
                    ønsker å benytte.
                    <br/>
                    <br/>
                    Om du bruker våre gratis-tjenester (som følger med ditt abonnement) uten å lage fraktetiketter eller
                    bestille
                    forsendelser gjennom våre tjenester, er det naturlig å anta at din drift er å benytte deg av gratis
                    tjenester uten å gjengjelde det med å bruke våre betalte tjenester, og vi forbeholder oss deretter
                    retten til å umiddelbart stenge din konto uten forvarsel.
                </p>
            </div>
            {/* 6. Dropper sin tjeneste slutt */}


            {/* 7. Vilkår for brukt av fraktetiketter start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">7. Vilkår for bruk av fraktetiktter</h3>
                <p className="lead">
                    Denne seksjonen forklarer vilkårene for kjøp og bruk av fraktetiketter bestilt gjennom Dropper sine
                    tjenester.
                    Skulle det skje at du bryter våre retningslinjer for bruk av fraktetiketter eller at det oppstår
                    ekstra
                    uforventede kostnader med din forsendelse forbeholder vi retten til å kunne fakturere deg og kreve
                    det
                    ekstra beløpet betalt.
                </p>
                <h4 className="h4 mt-10">7.1 Prosedyrer for fakturering av ekstra kostnader</h4>
                <p className="lead">
                    I de tilfellene der det påløper ekstra-kostnader assosiert med frakt bestilt gjennom Dropper vil du
                    bli varslet
                    om dette umiddelbart, og skulle det være midler på konto vil vi automatisk trekke fra disse midlene.
                    Du vil også ha muligheten til å gjøre opp for den utestående balansen på vår nettside.
                    <br/>
                    <br/>
                    Skulle det utestående beløpet ikke bli betalt i sin helhet innen 5 dager, vil vi automatisk prøve å
                    trekke
                    den tilknyttede betalingsmetoden for å gjøre opp for det utestående, opptil 3 ganger i løpet av 3
                    dager. Om
                    det viser seg å ikke være mulig å kunne trekke fra den utestående balansen enten fra din konto hos
                    våre tjenester,
                    eller gjennom din valgte betalingsmetode, vil vi gå videre med å videreføre kravet til factoring-
                    og/eller inkassoselskap.
                    <br/>
                    <br/>
                    Pålydende renter for det utestående beløpet vil påløpe fra opprinnelig forfallsdato (etter 7 dager).
                    Våre systemer vil automatisk fortsette alle krav til inkasso så snart forfallsdatoen er passert, og
                    du vil bli
                    holdt ansvarlig for alle juridiske kostnader og relaterte avgifter/gebyr/renter.
                </p>
                <h4 className="h4 mt-10">7.2 Fraktetikett</h4>
                <p className="lead">
                    Du som kunde er ansvarlig for at fraktetikettene på dine forsendelser er fullt synlige for
                    transportørene slik at
                    de kan håndtere dine forsendelser som normalt. Det er anbefalt å sørge for at forsendelsen din er
                    minst like stor
                    som fraktetiketten slik at det er plass til hele på én overflate.
                    <br/>
                    <br/>
                    Skulle det være feil med fraktetiketten som fører til at det må utføres manuelt arbeid vil det
                    oppstå
                    ekstra kostnader som vil bli fakturert til deg, i henhold til transportøren sine priser. Dropper har
                    laget en guide slik at det er enkelt for deg å unngå de vanligste feilene når det kommer til å
                    sette på fraktetiketter.
                </p>
                <h4 className="h4 mt-10">7.3 Fraktetikett gyldighet</h4>
                <p className="lead">
                    Forsendelser som er kjpt gjennom Dropper er gyldige i vanligvis 7 dager fra den dagen de ble bestilt
                    med mindre noe annet er skriftlig avtalt eller opplyst om. Når en fraktetikett er regnet som utløpt
                    / ugyldig
                    skal den ikke benyttes til å sende pakker.
                    <br/>
                    <br/>
                    Skulle en fraktetikett som er regnet som ugyldig/utløpet benyttes tross advarslene om dette, vil det
                    føre til
                    at du blir belastet ekstra (iht. punkt 9. Ekstra kostnader). Skulle en fraktetikett kanselleres vil
                    den også
                    regnes som ugyldig/utløpet og må heller ikke benyttes, da det også vil føre til ekstra kostander
                    (pkt. 9 Ekstra kostnader).
                </p>
                <h4 className="h4 mt-10">7.4 Gjenbruk av fraktetiketter</h4>
                <p className="lead">
                    Fraktetiketter som er bestilt gjennom Dropper må aldri gjenbrukes da dette kan føre til at pakken
                    medfører
                    feil i systemene til transportør, manuell håndtering, i tillegg til ekstrakostnader. Skulle en
                    fraktetikett
                    bli gjenbrukt vil du bli belastet ekstra i henhold til Punkt 9 - Ekstra kostnader.
                </p>
                <h4 className="h4 mt-10">7.5 Prisforskjell, ekstra kostnader, gebyrer .o.l</h4>
                <p className="lead">
                    Om en forsendelse bestilt gjennom Dropper viser seg å overskride informasjonen som var opplyst om av
                    deg som kunde
                    vil det føre til ekstra kostnader fra transportør. Vi tar ingen påslag på denne prisforskjellen, og
                    du vil bare bli belastet
                    for de faktiske kostnadene som dette medfører. Dette gjelder forøvrig alle ekstra kostnader som
                    kommer som resultat
                    av dine forsendelser.

                    <br/>
                    <br/>

                    Disse ekstra kostnadene kan oppstå opptil 180 dager etter at forsendelsen var sendt inn og
                    transportert.
                </p>
                <h4 className="h4 mt-10">7.6 Maks størrelse og krav til innpakking</h4>
                <p className="lead">
                    Alle transportører har sine egne vilkår og betingelser for innpakking av forsendelser. Om disse
                    vilkårene og kravene
                    ikke er møtt, vil det føre til ekstra kostnader i henhold til den enkelte transportør sine rater.
                    Dette gjelder
                    også dersom en forsendelse er oppgitt med lavere vekt enn den faktiske vekten i transport.

                    <br/>
                    <br/>

                    Skulle en pakke bryte de kravene og vilkårene fremstilt av transportør kan det føre til at pakken
                    vil
                    returneres til avsender uten at det vil bli gitt en refusjon for dette. Du vil også bli belastet for
                    frakten tilbake til avsender.
                </p>

                <h4 className="h4 mt-10">7.7 Business-pakke til privatperson eller omvendt</h4>
                <p className="lead">
                    Skulle du sende en pakke som bedriftspakke og den blir levert til en privatperson - eller omvendt,
                    der du sender en pakke som en pakke til privatperson men den blir levert til en bedrift, vil du
                    kunne bli
                    belastet for ekstra kostnader som følge av dette. I tillegg kan forsendelsen bli levert til et
                    hentested
                    i stedet for å bli levert til en bedrift, der det kan bli belastet ekstra kostnader for å endre på
                    pakken og leveringsmetoden.
                </p>

                <h4 className="h4 mt-10">7.8 Returer </h4>
                <h4 className="h4 mt-20">7.8.1 Retur av ikke-leverte pakke </h4>
                <p className="lead">
                    Om en forsendelse ikke kan bli levert fordi mottaker ikke er tilstede, eller om pakken ikke ble
                    hentet opp
                    hos et hentested vil forsendelsen bli returnert til avsender. Du vil bli belastet for de kostnadene
                    for å returnere forsendelsen tilbake til avsender. Kostnaden er den samme som det kostet å sende
                    pakken opprinnelig.
                </p>
                <h4 className="h4 mt-10">7.8.2 Forbudt gods </h4>
                <p className="lead">
                    Dersom en forsendelse ble avvist av transportøren som følge av at den inneholder forbudt/farlig gods
                    vil
                    den kunne bli returnert til avsender, uten at kostnadene for transport vil bli refundert. I tillegg
                    vil
                    du bli belastet kostnadene for at den blir returnert, som er lik den opprinnelige fraktkostnaden.
                </p>
            </div>
            {/* 7. Vilkår for brukt av fraktetiketter slutt*/}

            {/* 8. Klager start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">8. Klager</h3>
                <p className="lead">
                    Dropper er ikke en transportør og håndterer ikke forsendelsene som er opprettet gjennom våre
                    tjenester fysisk,
                    og har dermed intet ansvar for forsendelsen, dets innhold eller lignende. Når du bestiller frakt
                    gjennom
                    våre systemer aksepterer du Dropper sine vilkår, i tillegg til den valgte transportør sine vilkår,
                    krav, o.l.,
                    som inkluderer (men er ikke begrenset bare til) innpakkings-krav, maks-/minste/-mål/-dimensjoner,
                    klageprosedyrer, gebyrer, ekstra kostnader,
                    forsikringssaker for tapt/skadet gods.
                    <br/>
                    <br/>

                </p>
                <p className="lead">
                    I de tilfeller hvor en forsendelse går tapt eller blir skadet i transitt er det den enkelte
                    transportør
                    sitt ansvar og som skal holdes ansvarlig. I slike tilfeller skal du følge den enkelte transportør
                    sine
                    retningslinjer, vilkår, guider o.l. for å opprette en eventuell skadesak. Alle spørsmål om slike
                    ting
                    bes rettes mot den enkelte transportør da Dropper ikke er ansvarlig for disse forsendelsene.
                    <br/>
                    <br/>
                </p>
                <p className="lead">
                    Skulle det være uenighet om en ekstra belastning for våre tjenester eller for forsendelser bestilt
                    gjennom Dropper, må en slik klage være rettet til Dropper innen 30 kalenderdager fra tjenesten har
                    blitt
                    levert eller fakturert for, hva enn som kommer først. Dropper forbeholder seg retten til å avvise
                    enhver
                    klage som er forsinket.
                </p>
            </div>
            {/* 8. Kontakt slutt */}

            {/* 9. Ekstra kostnader start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">9. Ekstra kostnader, gebyrer o.l.</h3>
                <p className="lead">
                    Dropper er ikke en transportør og håndterer ikke forsendelsene som er opprettet gjennom våre
                    tjenester fysisk,
                    og har dermed intet ansvar for forsendelser, men i de tilfeller Dropper blir fakturert for ekstra
                    kostnader
                    tilhørende forsendelser bestilt av deg gjennom Dropper sine tjenester, forbeholder Dropper seg
                    retten til
                    å belaste deg for slike tilfeller, i tillegg til eventuelle interne håndteringsgebyr. Under kan du
                    se
                    en liste over hvilke tilfeller vi kan belaste deg ekstra for:
                </p>
                <ul className="lead" style={{marginLeft: "50px"}}>
                    <li>
                        Bruk av ugyldige/utløpte fraktetiketter: NOK 300 + fraktkostnader
                    </li>
                    <li>
                        Gjenbruk av fraktetiketter: NOK 300 + fraktkostnader
                    </li>
                    <li>
                        Bruk av kansellerte fraktetiketter: NOK 300 + fraktkostnader
                    </li>
                    <li>
                        Retur som følge av farlig gods avvist av transportør: NOK 300 + fraktkostnader
                    </li>
                    <li>
                        Slette konto: NOK 300 + fraktkostnadene
                    </li>
                    <li>
                        Manuelt internt håndteringsgebyr: NOK 500
                    </li>
                </ul>
            </div>
            {/* 9. Ekstra kostnader slutt */}

            {/* 10. Data Processing Agreement start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">10. Datahåndtering</h3>
                <p className="lead">
                    Dropper håndterer personlig data som følge av at man skal kunne bestille forsendelser,
                    fraktetiketter,
                    tilby en nøyaktig veiledende pris o.l. Denne typen data er f.eks. full adresse, navn, telefon, og
                    e-post til dine kunder.
                    <br/>
                    Offisielt betyr dette at din bedrift er en "data controller", mens Dropper er en "data processor" i
                    henhold
                    til GDPR-reguleringene i EU. Dette gjør at vi tar sterke grep for å sikre personvernet til dine
                    kunder
                    og sikre at de ikke faller i gale hender.
                    <br/>
                    <br/>
                    Som en datahåndterer (data processor) for din bedrift, vil vi i henhold til GDPR-regelverket føye
                    oss
                    etter regelverket når det kommer til lagring, tilgang, deling, sletting og håndtering av disse
                    dataene.
                    Som en kunde av oss aksepterer du våre vilkår når det gjelder vår <a href="#"
                                                                                         className="bg-success text-primary">datahåndteringsavtale</a>
                </p>
            </div>
            {/* 10. Datahåndtering slutt */}

            {/* 11. Data Processing Agreement start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">11. Oppsigelse av avtale</h3>
                <p className="lead">
                    Begge partene kan når som helst velge å avslutte samarbeidet ved å sende et 14-dagers skriftlig
                    varsel
                    om oppsigelse. Ved avslutning av samarbeidet vil din konto bli avsluttet på slutten av den avtalte
                    perioden.
                    Har du midler igjen på din konto (positiv balanse) hos oss vil vi ordne tilbakebetaling av disse
                    midlene etter avslutning av konto.
                </p>
            </div>
            {/* 11. Datahåndtering slutt */}

            {/* 12. Avvik start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">12. Avvik</h3>
                <p className="lead">
                    Ved avvik mellom et kjøp og disse vilkårene nevnt i denne avtalen, skal denne avtalen overgå.
                    Vilkårene, er som nevnt i pkt 4 regulert av norsk rett ved Sogn og Fjordane tingrett.
                </p>
            </div>
            {/* 12. Avvik slutt */}

        </div>
        <FooterOne footerLight/>
    </Layout>);
};

export default Terms;
