import React from 'react';
import SubProcessorContent from './components/career/SubProcessorContent';
import JobHeader from './components/career/JobHeader';
import PageMeta from './components/common/PageMeta';
import CtaTwo from './components/cta/CtaTwo';
import FooterOne from './layout/Footer/FooterOne';
import Navbar from './layout/Header/Navbar';
import Layout from './layout/Layout';

const SubProcessors = () => {
    return (
        <Layout>
            <PageMeta/>
            <Navbar/>
            <JobHeader/>
            <SubProcessorContent/>
            <CtaTwo/>
            <FooterOne footerLight/>
        </Layout>
    );
};

export default SubProcessors;
