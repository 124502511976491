import React from 'react';

const SubProcessorContent = () => {
    return (
        <>
            <section className='job-details ptb-120'>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-8 pe-5'>
                            <div className='job-details-wrap'>
                                <h2>Underdatabehandlere</h2>
                                <p>
                                    For å sørge for åpenhet rundt våre produkter og tjenester har vi en offentlig liste
                                    av alle våre underdatabehandlere som vi benytter oss av. Under ser du en liste
                                    med hver enkelt underdatabehandler, og hvorfor vi benytter oss av leverandørene.
                                </p>
                                <div className='job-details-info mt-5'>
                                    <h3 className='h3'>Infrastruktur</h3>
                                    <p className="">
                                        Dropper har ingen fysiske lokasjoner hvor vi har infrastruktur, men vi benytter
                                        oss av bransjeledende leverandører som leverer pålitelige tjenester og
                                        produkter.
                                    </p>
                                    <p>
                                        <b>Liste:</b>
                                    </p>
                                    <ul className='content-list list-unstyled'>
                                        <li>
                                            <b>Cloudflare:</b> IT-sikkerhet og DNS-tjenester.
                                        </li>
                                        <li>
                                            <b>DigitalOcean, LLC:</b> Leverandør av servere og digital infrastruktur
                                        </li>
                                        <li>
                                            <b>Terrahost AS:</b> Leverandør av servere og digital infrastruktur
                                        </li>
                                        <li>
                                            <b>Posthog:</b> Personvern-vennlig system for analyser og statistikk av
                                            brukere
                                        </li>
                                        <li>
                                            <b>Twilio Ireland Limited:</b> Leverandør av kommunikasjons-tjenester som
                                            e-post
                                        </li>
                                        <li>
                                            <b>OnlineCity ApS:</b> Leverandør av kommunkasjons-tjenester som SMS
                                        </li>
                                        <li>
                                            <b>PrintNode, LLC:</b> Levering av software rundt online printing
                                        </li>
                                        <li>
                                            <b>LiveChat, Inc.:</b> Leverandør av live-chat løsning
                                        </li>
                                        <li>
                                            <b>Frontapp, inc:</b> Leverandør av kunde-kommunikasjon som e-post
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SubProcessorContent;
