import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';
import CtaSubscribe from '../../components/cta/CtaSubscribe';
import ShopIntegrationsList from '../../components/integration/ShopIntegrationsList';
import FooterOne from '../../layout/Footer/FooterOne';
import Layout from '../../layout/Layout';
import CarrierIntegrationsList from "../../components/integration/CarrierIntegrationsList";

const Integrations = () => {
    return (
        <Layout>
            <PageMeta title='Integrations - Software &amp; IT Solutions HTML Templat'/>
            <Navbar/>
            <PageHeader
                title='Våre integrasjoner'
                desc='Under kan du se alle plattformer og transportører vi har integrasjoner mot som du kan komme i gang med umiddelbart! Ser du noe på listen som mangler eller som du har behov for - ta kontakt med oss!'
            />
            <ShopIntegrationsList/>
            <CarrierIntegrationsList/>
            <CtaSubscribe/>
            <FooterOne/>
        </Layout>
    );
};

export default Integrations;
