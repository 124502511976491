import React from 'react';

const IntegrationOne = () => {
    return (
        <>
            <section className="our-integration ptb-120 bg-light">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-7 col-md-12">
                            <div className="section-heading">
                                <h5 className="h6 text-primary">Integrasjon</h5>
                                <h2>Integrasjoner med de mest populære plattformene</h2>
                                <p>
                                    Vi har direkte integrasjoner med de mest populære plattformene slik at du kan komme
                                    i gang
                                    med vårt system på få minutter. Vi jobber også med å få på plass integrasjoner mot
                                    flere plattformer.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative w-100">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <dov
                                    className="position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5">
                                    <div className="position-relative connected-app-content text-center">
                                        <div
                                            className="integration-logo bg-white rounded-3 shadow-sm p-2 d-inline-block">
                                            <img src="/assets/logos/shopify_logo.png" width={80} alt="integration"
                                                 className="img-fluid"/>
                                        </div>
                                        <h5>Shopify</h5>
                                    </div>
                                </dov>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div
                                    className="position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5">
                                    <div className="position-relative connected-app-content text-center">
                                        <div
                                            className="integration-logo bg-white rounded-3 shadow-sm p-2 d-inline-block">
                                            <img src="/assets/logos/woocommerce.png" width={60} alt="integration"
                                                 className="img-fluid"/>
                                        </div>
                                        <h5>WooCommerce</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div
                                    className="position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5">
                                    <div className="position-relative connected-app-content text-center">
                                        <div
                                            className="integration-logo bg-white rounded-3 shadow-sm p-2 d-inline-block">
                                            <img src="/assets/logos/BigCommerce.webp" width={80} alt="integration"
                                                 className="img-fluid"/>
                                        </div>
                                        <h5>BigCommerce</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div
                                    className="position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5">
                                    <div className="position-relative connected-app-content text-center">
                                        <div
                                            className="integration-logo bg-white rounded-3 shadow-sm p-2 d-inline-block">
                                            <img src="/assets/logos/Magento-Commerce.png" width={80} alt="integration"
                                                 className="img-fluid"/>
                                        </div>
                                        <h5>Magento Commerce</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div
                                    className="position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5">
                                    <div className="position-relative connected-app-content text-center">
                                        <div
                                            className="integration-logo bg-white rounded-3 shadow-sm p-2 d-inline-block">
                                            <img src="/assets/logos/mystore.png" width={80} alt="integration"
                                                 className="img-fluid"/>
                                        </div>
                                        <h5>mystore.no</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div
                                    className="position-relative text-decoration-none connected-app-single border border-2 promo-border-hover mt-4 transition-base bg-white rounded-custom d-block overflow-hidden p-5">
                                    <div className="position-relative connected-app-content text-center">
                                        <div
                                            className="integration-logo bg-white rounded-3 shadow-sm p-2 d-inline-block">
                                            <img
                                                src="https://cdn.sanity.io/images/7hja5omh/production/18dc2d8522b56cb9972916f91e8632e1ca6a22ef-702x450.png"
                                                width={80} alt="integration"
                                                className="img-fluid"/>
                                        </div>
                                        <h5>Quickbutik</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default IntegrationOne;
