import React, {useState} from "react";
import {Link} from "react-router-dom";
import ModalVideo from "react-modal-video";
import HeroTitle from "../../components/common/HeroTitle";
import GradientDiv from "../../components/GradientDiv";

const HeroOne = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <GradientDiv height="2px"/>
            <section className="hero-section ptb-120 text-white bg-gradient">
                <div className="container mt-lg-1 mt-md-5 mt-sm-5 mt-xl-1">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="hero-content-wrap pt-120 pt-lg-5">
                                <HeroTitle
                                    title="Logistikk for bedrifter"
                                    desc="Dropper er nettbutikkenes førstevalg når det kommer til ordre- og frakthåndtering, priser og kundeservice."
                                />
                                <div
                                    className="action-btn mt-5 align-items-center text-center d-block d-sm-flex d-lg-flex d-md-flex"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                >
                                    <ModalVideo
                                        channel="youtube"
                                        isOpen={false}
                                        videoId="hAP2QF--2Dg"
                                        onClose={() => setOpen(false)}
                                    />
                                    <a
                                        href="https://app.dropper.no/authentication/register"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="btn btn-primary me-3"
                                    >
                                        Kom i gang nå
                                        <i
                                            className="fas fa-chevron-right"
                                            style={{
                                                marginLeft: "15px"
                                            }}
                                        />
                                    </a>
                                    <Link
                                        to="/kontakt-oss"
                                        className="btn btn-outline-warning text-decoration-none d-inline-flex align-items-center mt-3 mt-lg-0 mt-md-0"
                                    >
                                        Kontakt oss{" "}
                                    </Link>
                                </div>
                                {/*
                                <div className="row justify-content-lg-start mt-60">
                                    <h6 className="text-white-70 mb-2">Disse setter sin tillit til Dropper:</h6>
                                    <div className="col-4 col-sm-3 my-2 ps-lg-0">
                                        <img
                                            src="https://www.intin.no/wp-content/uploads/2019/12/intinlogonegativv2.svg"
                                            alt="client"
                                            className="img-fluid"
                                            style={{
                                                height: "100px"
                                            }}
                                        />
                                    </div>
                                    <div className="col-4 col-sm-3 my-2">
                                        <img
                                            src="https://static.outnorth.com/image/upload/d_no_image_chlzht.png,f_auto,fl_lossy,q_auto/v1/static/outnorth-logotype.png"
                                            alt="client"
                                            className="img-fluid"
                                            style={{
                                                marginTop: "30px",
                                                height: "40px"
                                            }}
                                        />
                                    </div>
                                    <div className="col-4 col-sm-3 my-2">
                                        <img
                                            src="https://media.power-cdn.net/images/logos/powerno/logo.svg"
                                            alt="client"
                                            className="img-fluid"
                                            style={{
                                                height: "100px"
                                            }}
                                        />
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 mt-5">
                            <div className="hero-img position-relative circle-shape-images">
                                <ul className="position-absolute animate-element parallax-element circle-shape-list">
                                    <li className="layer" data-depth="0.03">
                                        <img
                                            src="assets/img/shape/circle-1.svg"
                                            alt="shape"
                                            className="circle-shape-item type-0 hero-1"
                                        />
                                    </li>
                                    <li className="layer" data-depth="0.02">
                                        <img
                                            src="assets/img/shape/circle-1.svg"
                                            alt="shape"
                                            className="circle-shape-item type-1 hero-1"
                                        />
                                    </li>
                                    <li className="layer" data-depth="0.04">
                                        <img
                                            src="assets/img/shape/circle-1.svg"
                                            alt="shape"
                                            className="circle-shape-item type-2 hero-1"
                                        />
                                    </li>
                                    <li className="layer" data-depth="0.04">
                                        <img
                                            src="assets/img/shape/circle-1.svg"
                                            alt="shape"
                                            className="circle-shape-item type-3 hero-1"
                                        />
                                    </li>
                                    <li className="layer" data-depth="0.03">
                                        <img
                                            src="assets/img/shape/circle-1.svg"
                                            alt="shape"
                                            className="circle-shape-item type-4 hero-1"
                                        />
                                    </li>
                                    <li className="layer" data-depth="0.03">
                                        <img
                                            src="assets/img/shape/circle-1.svg"
                                            alt="shape"
                                            className="circle-shape-item type-5 hero-1"
                                        />
                                    </li>
                                </ul>

                                <img
                                    src="assets/img/screenshots/Dashboard_Screenshot1.png"
                                    alt="hero img"
                                    className="img-fluid position-relative z-5"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GradientDiv height="7.5px"/>
        </>
    );
};

export default HeroOne;
