import React from 'react';

const SecurityPointTwo = () => {
    return (
        <>
            <section className='feature-section pt-60 pb-120'>
                <div className='container'>
                    <div className='row align-items-lg-center justify-content-between'>
                        <div className='col-lg-5 order-lg-1 mb-7 mb-lg-0'>
                            <div className='mb-4'>
                                <h2>Nyeste teknologien på markedet</h2>
                                <p>
                                    Vi benytter oss av plattformer som har fokus på sikkerhet og personvern i like stor
                                    grad som oss. Vår plattform er sikret bak Cloudflare sine systemer som sørger for
                                    maksimal
                                    oppetid og sikkerhet. {' '}
                                </p>
                            </div>
                            <ul className='list-unstyled d-flex flex-wrap list-two-col mt-5'>
                                <li>
                  <span>
                    <i className='fal fa-rectangle-vertical-history fa-2x text-primary mb-4'></i>
                  </span>
                                    <h3 className='h5'>Data Redundans</h3>
                                    <p>
                                        All data er lagret med en pålitelig leverandør som har lokasjoner rundt om i
                                        Europa.
                                    </p>
                                </li>
                                <li>
                  <span>
                    <i className='fal fa-hand-holding-box fa-2x text-primary mb-4'></i>
                  </span>
                                    <h3 className='h5'>DDOs Mitigering</h3>
                                    <p>
                                        Cloudflare sørger for at ingen onde aktører utpresser oss med DDOS angrep.{' '}
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className='col-lg-6 order-lg-2'>
                            <div className='pr-lg-4 position-relative'>
                                <div className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'>
                                    <img
                                        src='assets/icons/undraw_done_checking.svg'
                                        alt=''
                                        className='img-fluid rounded-custom shadow-sm'
                                    />
                                    <div
                                        className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 bottom--40 right--40 bottom-right'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SecurityPointTwo;
