import React from 'react';
import SectionTitle from '../common/SectionTitle';

const WorkProcess = () => {
    return (
        <>
            <section className='work-process ptb-120'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6 col-md-10'>
                            <SectionTitle
                                subtitle='Prosessen vår'
                                title='Vi ordner det for deg'
                                description='Vi har gjort det superenkelt å komme i gang med vår løsning, og du kan lage en konto og begynne å sende med våre fraktavtaler på et par minutter!'
                                centerAlign
                            />
                        </div>
                    </div>
                    <div className='row align-items-center justify-content-between'>
                        <div className='col-lg-5 col-md-12 order-1 order-lg-0'>
                            <div className='img-wrap'>
                                <img
                                    src='assets/img/widget-12.png'
                                    alt='work process'
                                    className='img-fluid rounded-custom'
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
                            <ul className='work-process-list list-unstyled'>
                                <li className='d-flex align-items-start mb-4'>
                                    <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                                        <i className='far fa-folder-tree fa-2x'></i>
                                    </div>
                                    <div className='icon-content'>
                                        <span className='text-primary h6'>Steg 1</span>
                                        <h3 className='h5 mb-2'>Opprett en gratis konto</h3>
                                        <p>
                                            Lag en konto med oss - vi trenger bare e-post og passord, i tillegg til
                                            grunnleggende
                                            informasjon om din bedrift.
                                        </p>
                                    </div>
                                </li>
                                <li className='d-flex align-items-start mb-4'>
                                    <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                                        <i className='far fa-bezier-curve fa-2x'></i>
                                    </div>
                                    <div className='icon-content'>
                                        <span className='text-primary h6'>Steg 2</span>
                                        <h3 className='h5 mb-2'>Koble til butikkene dine</h3>
                                        <p>
                                            Dropper støtter de fleste plattformene som Shopify, WooCommerce. Start en
                                            integrasjon
                                            mellom Dropper og dine butikker på under 2 minutter.
                                        </p>
                                    </div>
                                </li>
                                <li className='d-flex align-items-start mb-4'>
                                    <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                                        <i className='far fa-layer-group fa-2x'></i>
                                    </div>
                                    <div className='icon-content'>
                                        <span className='text-primary h6'>Steg 3</span>
                                        <h3 className='h5 mb-2'>Importering av bestillingene dine</h3>
                                        <p>
                                            Dropper henter automatisk inn bestillingene fra nettbutikkene dine og gjør
                                            de klar til håndtering.
                                        </p>
                                    </div>
                                </li>
                                <li className='d-flex align-items-start mb-4 mb-lg-0'>
                                    <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
                                        <i className='far fa-truck fa-2x'></i>
                                    </div>
                                    <div className='icon-content'>
                                        <span className='text-primary h6'>Steg 4</span>
                                        <h3 className='h5 mb-2'>Send bestillingene på 1-2-3</h3>
                                        <p>
                                            Print pakkesedler og frakt-etiketter på få sekunder, og send bestillingene
                                            dine!
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WorkProcess;
