import React, {useState} from "react";

const PriceThree = () => {
    const [isAnnual, setIsAnnual] = useState(false);

    return (
        <section className="price-two ptb-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="section-heading text-center">
                            <h2>
                                Abonnement for alle.
                            </h2>
                            <p>
                                Vi har oppdatert prismodellen vår for alle kunder, og har gjort et forsøk på å gjøre det
                                veldig enkelt å skjønne
                                hvor mye det faktisk koster å bruke Dropper.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 flex-row text-center mb-4">
                        <ul
                            className='nav nav-pills mb-4 pricing-tab-list'
                            id='pills-tab'
                            role='tablist'
                        >
                            <li className='nav-item' role='presentation'>
                                <button
                                    className='active'
                                    id='pills-home-tab'
                                    data-bs-toggle='pill'
                                    data-bs-target='#pills-home'
                                    type='button'
                                    role='tab'
                                    aria-controls='pills-home'
                                    aria-selected='true'
                                    onClick={() => {
                                        setIsAnnual(false);
                                    }}
                                >
                                    Månedlig fakturering
                                </button>
                            </li>
                            <li className='nav-item' role='presentation'>
                                <button
                                    id='pills-profile-tab'
                                    data-bs-toggle='pill'
                                    data-bs-target='#pills-profile'
                                    type='button'
                                    role='tab'
                                    aria-controls='pills-profile'
                                    aria-selected='false'
                                    className=''
                                    onClick={() => {
                                        setIsAnnual(true);
                                    }}
                                >
                                    Årlig fakturering (spar opptil 17.5%)
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-6 col-md-6">
                        <div
                            className="position-relative single-pricing-wrap rounded-custom bg-gradient text-white p-5 mb-4 mb-lg-0">
                            <div className="pricing-header mb-32">
                                <h3 className="package-name text-warning d-block">Premium</h3>
                                <h4 className="display-6 fw-semi-bold">
                                    {
                                        isAnnual ? (
                                            <>
                                                1188,-<span>/år</span>
                                                <span><label>(Spar 17.5%)</label></span>
                                            </>
                                        ) : (
                                            <>
                                                99,-<span>/mnd</span>
                                            </>
                                        )
                                    }
                                </h4>
                                <p className="">
                                    For deg med en godt etablert nettbutikk som vet volumet ditt og behovene dine.
                                </p>
                            </div>
                            <div className="pricing-info mb-4">
                                <ul className="pricing-feature-list list-unstyled">
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Alle fordelene i "Oppstart", i tillegg til:
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Koble til så mange butikk-plattformer du vil.
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        1 bruker med tilgang (<b>99,-</b> per ekstra bruker)
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Opptil <b>3000</b> ordrer hver måned.
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Retur-portal for slutt-kunder.
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Automatisk printing med AutoPrint
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Avansert sporing på forsendelser
                                    </li>
                                    <li>
                                        <i className="far fa-x text-danger text-muted me-2"></i>
                                        Faktura på etterskudd / kreditt
                                    </li>
                                    <li>
                                        <i className="far fa-x text-danger text-muted me-2"></i>
                                        Muligheter for API-tilgang
                                    </li>
                                </ul>
                            </div>
                            <a
                                href="https://app.dropper.no/authentication/register"
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-primary mt-2 w-100"
                            >
                                Prøv gratis i 2 måneder
                                <i className="fas fa-chevron-right"
                                   style={{marginLeft: "10px"}}
                                ></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="position-relative single-pricing-wrap rounded-custom bg-white custom-shadow p-5 mb-4 mb-lg-0">
                            <div className="pricing-header mb-32">
                                <h3 className="package-name text-primary d-block">Enterprise</h3>
                                <h4 className="display-6 fw-semi-bold">
                                    <>
                                        Scroll ned
                                    </>
                                </h4>
                                <p className="">
                                    For deg som håndterer et stort antall ordre-volum hver måned, og trenger det beste
                                    systemet
                                    for din bedrift.
                                </p>
                            </div>
                            <div className="pricing-info mb-4">
                                <ul className="pricing-feature-list list-unstyled">
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Alle fordeler med "vekst", i tillegg til:
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Koble til så mange butikk-plattformer du vil
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Fra og med 500+ ordrer i måneden.
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>{" "}
                                        Faktura hver 2. uke v/ kredittsjekk*
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Kundeservice på e-post og chat med 12t SLA
                                    </li>
                                    <li>
                                        <i className="far fa-check-circle text-success me-2"></i>
                                        Skreddersydd oppfølging
                                    </li>
                                    <li>
                                        <i className="far fa-arrow-down text-success me-2"></i>
                                        Scroll ned for mer informasjon
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PriceThree;
