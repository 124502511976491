import React from 'react';

const ReviewTwo = ({reqPage}) => {
    return (
        <>
            <div className='col-xl-5 col-lg-5 col-md-12 order-1 order-lg-0'>
                <div className='testimonial-tab-slider-wrap mt-5'>
                    {reqPage ? (
                        <h1 className='fw-bold text-white display-5'>
                            Etterspør beta-tilgang til Dropper
                        </h1>
                    ) : (
                        <h2 className='text-white'>Etterspør beta-tilgang til Dropper</h2>
                    )}
                    <p>
                        Kom i gang med et moderne webpanel for å adminstrere alle dine ordrer på én og samme plass. Det
                        er slutt
                        på trege nettsider med utdatert design. Vi ønsker deg velkommen til fremtiden.
                    </p>
                    <hr/>
                </div>
            </div>
        </>
    );
};

export default ReviewTwo;
