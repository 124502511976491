import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import ContactBox from '../components/contact/ContactBox';
import ContactFormTwo from '../components/contact/ContactFormTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const Contact = () => {
    return (
        <Layout>
            <PageMeta title='Kontakt Oss - Dropper'/>
            <Navbar/>
            <PageHeader
                title='Kontakt oss'
                desc='Har du spørsmål, trenger hjelp eller ønsker å komme i kontakt med oss kan du enkelt gjøre det med de angitte metodene under. Vårt kundesenter holder åpent hverdager 08:00 - 16:00'
            />
            <ContactBox/>
            <ContactFormTwo/>
            <FooterOne
                style={{
                    background:
                        "url('assets/img/page-header-bg.svg')no-repeat bottom right",
                }}
            />
        </Layout>
    );
};

export default Contact;
