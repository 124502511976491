import React from 'react';
import SectionTitle from "../common/SectionTitle";

const FeatureImgContentFour = () => {
    return (
        <>
            <section className={`feature-section-two pt-60 pb-120 `}>
                <div className='container'>
                    <div className='row align-items-center justify-content-between'>
                        <div className='col-lg-5 col-md-12'>
                            <SectionTitle
                                subtitle='Mer info'
                                title='Den beste løsningen for din nettbutikk'
                                description={`Vi har sett på svakhetene hos våre konkurrenter, og gjort de om til en av våre markedsledende
                                fordeler. Vi forbedrer oss hver dag basert på feedback fra kunder.`}
                            />
                            <div>
                                <ul className='list-unstyled mt-5'>
                                    <li className='d-flex align-items-start mb-4'>
                                        <div className='icon-box rounded me-4'>
                                            <i className='fad fa-gauge-high text-primary'></i>
                                        </div>
                                        <div className='icon-content'>
                                            <h3 className='h5'>Lynraskt system</h3>
                                            <p>
                                                Våre systemer er designet fra bunnen av for å yte best mulig og sørge
                                                for at det brukes minst mulig tid for å håndtere ordrer, forsendelser og
                                                mer.
                                            </p>
                                        </div>
                                    </li>
                                    <li className='d-flex align-items-start mb-4'>
                                        <div className='icon-box rounded me-4'>
                                            <i className='fad fa-desktop text-primary'></i>
                                        </div>
                                        <div className='icon-content'>
                                            <h3 className='h5'>God oversikt</h3>
                                            <p>
                                                Vi har utviklet et brukervennlig dashboard. Du kan enkelt bestille
                                                frakt, spore forsendelser, sende SMS og e-poster fra én plattform.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-7'>
                            <div className='feature-img-wrap position-relative d-flex flex-column align-items-end'>
                                <ul className='img-overlay-list list-unstyled position-absolute'>
                                    <li
                                        className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                                        data-aos='fade-right'
                                        data-aos-delay='50'
                                    >
                                        <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                                        <h6 className='mb-0'>Opprett en konto</h6>
                                    </li>
                                    <li
                                        className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                                        data-aos='fade-right'
                                        data-aos-delay='100'
                                    >
                                        <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                                        <h6 className='mb-0'>Velg abonnement</h6>
                                    </li>
                                    <li
                                        className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                                        data-aos='fade-right'
                                        data-aos-delay='150'
                                    >
                                        <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                                        <h6 className='mb-0'>Integrer oss med din nettbutikk</h6>
                                    </li>
                                    <li
                                        className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                                        data-aos='fade-right'
                                        data-aos-delay='150'
                                    >
                                        <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                                        <h6 className='mb-0'>Send med våre fraktavtaler</h6>
                                    </li>
                                </ul>
                                <img
                                    src='/assets/icons/undraw_maker_launch.svg'
                                    alt='feature'
                                    className='img-fluid rounded-custom'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImgContentFour;
