import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const CtaTwo = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <section className='bg-gradient ptb-120 position-relative overflow-hidden'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8 col-md-10'>
                            <div className='subscribe-info-wrap text-center position-relative z-2'>
                                <SectionTitle
                                    subtitle="Det tar bare et par minutter"
                                    title='Kom i gang i nå, helt gratis'
                                    description={`.. og du betaler bare for det du bruker. Det er ingen bindingstid og ingen uforventede kostnader.`}
                                    dark
                                />
                                <div className='form-block-banner mw-60 m-auto mt-5 row'>
                                    <div className="col-6">
                                        <a
                                            href="https://app.dropper.no/authentication/register"
                                            target="_blank"
                                            rel="noreferrer"
                                            className='btn btn-primary'
                                        >
                                            <i className='fal fa-arrow-right'/>
                                            {" "} Kom i gang nå
                                        </a>
                                    </div>
                                    <div className="col-6">
                                        <Link to='/kontakt-oss' className='ml-2 pl-2 btn btn-success'>
                                            <i className='fal fa-phone'/>
                                            {" "}Kontakt oss
                                        </Link>
                                    </div>
                                </div>
                                <ul
                                    className='nav justify-content-center subscribe-feature-list mt-4'
                                    data-aos='fade-up'
                                    data-aos-delay='100'
                                >
                                    <li className='nav-item'>
                    <span>
                      <i className='far fa-check-circle text-primary me-2'></i>
                      Gratis å komme i gang
                    </span>
                                    </li>
                                    <li className='nav-item'>
                    <span>
                      <i className='far fa-check-circle text-primary me-2'></i>
                      Gode fraktpriser i Norge og utlandet
                    </span>
                                    </li>
                                    <li className='nav-item'>
                    <span>
                      <i className='far fa-check-circle text-primary me-2'></i>
                      God kundeservice
                    </span>
                                    </li>
                                    <li className='nav-item'>
                    <span>
                      <i className='far fa-check-circle text-primary me-2'></i>
                      Ingen bindingstid
                    </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        className='
              bg-circle
              rounded-circle
              circle-shape-3
              position-absolute
              bg-dark-light
              left-5
            '
                    ></div>
                    <div
                        className='
              bg-circle
              rounded-circle
              circle-shape-1
              position-absolute
              bg-warning
              right-5
            '
                    ></div>
                </div>
            </section>
        </>
    );
};

export default CtaTwo;
