import React from 'react';
import SectionTitle from "../common/SectionTitle";

const FeatureImg = () => {
    return (
        <>
            <section
                className={`feature-section pt-60 pb-120`}
            >
                <div className='container'>
                    <div className='row align-items-lg-center justify-content-between'>
                        <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
                            <div className='mb-4' data-aos='fade-up'>
                                <SectionTitle
                                    subtitle=" "
                                    title='Vi er din samarbeidspartner'
                                    description={`Uansett hvor mange forsendelser du sender hver måned er vi helt sikre på
                                    at vi er det rette valget for deg.`}
                                />
                            </div>
                            <ul className="list-unstyled mt-5">
                                <li className="d-flex align-items-start mb-4">
                                    <div className="icon-box rounded me-4">
                                        <i className="fad fa-money-bill-wave text-primary"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h3 className="h5">Spar penger på våre fraktavtaler</h3>
                                        <p>
                                            Vi tilbyr ekstremt konkurransedyktige fraktpriser uavhengig av frakt-volum.
                                            Med våre fraktavtaler sparer du penger som du igjen kan investere i din
                                            drift.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4">
                                    <div className="icon-box rounded me-4">
                                        <i className="fad fa-headset text-primary"></i>
                                    </div>
                                    <div className="icon-content">
                                        <h3 className="h5">God kundeservice</h3>
                                        <p>
                                            Vårt mål er å hjelpe deg håndtere dine bestillinger på en så god måte som
                                            mulig, og det siste vi ønsker
                                            er misfornøyde kunder. Vi har som mål å alltid besvare henvendelser innen 60
                                            minutter.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='col-lg-6 order-lg-1'>
                            <div className='pr-lg-4 mt-md-4 position-relative'>
                                <div
                                    className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'
                                    data-aos='fade-up'
                                    data-aos-delay='50'
                                >
                                    <img
                                        src='/assets/icons/undraw_growth_analytics_re_pyxf.svg'
                                        alt=''
                                        className='img-fluid'
                                    />
                                    <div
                                        className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FeatureImg;
