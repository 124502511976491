import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import SecurityPointTwo from '../../components/feature-img-content/SecurityPointTwo';
import SecurityPointOne from '../../components/features/SecurityPointOne';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const SecurityPoints = () => {
    return (
        <Layout>
            <PageMeta title='Sikkerhet - Dropper'/>
            <Navbar/>
            <PageHeader
                title='Sikkerhet tatt på alvor'
                desc="Vi forstår at mange synes sikkerhet er ekstremt viktig når de sammenligner plattform, vi ønsker å gå nærmere i detaljer hva Dropper har gjort for å sikre seg."
            />
            <SecurityPointOne paddingTop/>
            <SecurityPointTwo/>
            <FooterOne/>
        </Layout>
    );
};

export default SecurityPoints;
