import React from 'react';
import {Link} from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const PriceTwo = () => {
    return (
        <>
            <section
                className='pricing-section position-relative overflow-hidden bg-dark text-white pt-120'
            >
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6 col-md-12'>
                            <SectionTitle
                                subtitle='For våre større kunder'
                                title='Enterprise volum'
                                description={`Dersom du har et ekstremt stort volum med pakker hver dag som skal håndteres ønsker vi deg velkommen til vår plattform.`}
                                dark
                                centerAlign
                            />
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8 col-md-10'>
                            <div
                                className='pricing-content-wrap mb--100 bg-white rounded-custom shadow-lg border d-fle z-10 position-relative'>
                                <div className='price-feature-col pricing-feature-info left-radius bg-primary-soft p-5'>
                                    <ul
                                        className='list-unstyled pricing-feature-list pricing-included-list mb-0'
                                        data-aos='fade-left'
                                    >
                                        <li className='py-1'>
                                            <i className='fas fa-check-circle text-primary me-2'></i>
                                            Koble til så mange nettbutikker du ønsker
                                        </li>
                                        <li className='py-1'>
                                            <i className='fas fa-check-circle text-primary me-2'></i>
                                            Skreddersydd oppfølging
                                        </li>
                                        <li className='py-1'>
                                            <i className='fas fa-check-circle text-primary me-2'></i>
                                            Faktura hver 2. uke
                                        </li>
                                        <li className='py-1'>
                                            <i className='fas fa-check-circle text-primary me-2'></i>
                                            24/7 Kundeservice, SLA: &lt;6t
                                        </li>
                                        <li className='py-1'>
                                            <i className='fas fa-check-circle text-primary me-2'></i>
                                            Integrasjon med vårt API-system
                                        </li>
                                        <li className='py-1'>
                                            <i className='fas fa-check-circle text-primary me-2'></i>
                                            .. og mer
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    className='price-feature-col pricing-action-info p-5'
                                    data-aos='fade-right'
                                >
                                    <ul
                                        className='nav nav-pills mb-4 pricing-tab-list'
                                        id='pills-tab'
                                        role='tablist'
                                    >
                                        <li className='nav-item' role='presentation'>
                                            <button
                                                className='active'
                                                id='pills-home-tab'
                                                data-bs-toggle='pill'
                                                data-bs-target='#pills-home'
                                                type='button'
                                                role='tab'
                                                aria-controls='pills-home'
                                                aria-selected='true'
                                            >
                                                Månedlig
                                            </button>
                                        </li>
                                    </ul>
                                    <div className='tab-content' id='pills-tabContent'>
                                        <div
                                            className='tab-pane fade active show'
                                            id='pills-home'
                                            role='tabpanel'
                                            aria-labelledby='pills-home-tab'
                                        >
                                            <h3 className='h5'>Enterprise Månedlig</h3>
                                            <p>
                                                Abonnement faktureres basert på volum av pakker hver måned for de med en
                                                enorm mengde pakker hver dag som skal håndteres på en effektiv og
                                                oversiktlig måte.
                                            </p>
                                            <div className='pricing-price mt-5'>
                                                <h4 className='h1 fw-bold'>
                                                    Fra 999,- <span>/mnd</span>
                                                </h4>
                                                <hr className="bg-dark"/>
                                                <p>
                                                    Med følgende tillegg:
                                                </p>
                                                <h4 className='h4 fw-bold'>
                                                    Fra 299,- <span>per 1000 pakker i mnd</span>
                                                </h4>
                                            </div>
                                            <div className="col-12 mt-4">
                                                <Link
                                                    to="/kontakt-oss"
                                                    className='btn btn-primary mt-3 text-center'
                                                >
                                                    Kontakt oss for tilbud
                                                    <i className="fas fa-chevron-right"
                                                       style={{marginLeft: "10px"}}
                                                    ></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-circle rounded-circle circle-shape-1 position-absolute bg-warning left-5'></div>
                <div className='white-space-100 bg-white w-100'></div>
            </section>
        </>
    );
};

export default PriceTwo;
