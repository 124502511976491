import SectionTitle from "../common/SectionTitle";

const FeatureShippingRates = () => (
    <section>
        <div className="container mb-5">
            <SectionTitle
                subtitle='Vitnemål'
                title='Våre veiledende avtaler'
                description='Her kan du sjekke et utdrag av våre konkurransedyktige priser med de ulike transportørene!'
                centerAlign
            />
            <div className='row pt-60'>
                <div className='col-lg-6 mt-4'>
                    <div
                        className='position-relative d-flex flex-column h-100 flex-wrap bg-warning-soft p-5 rounded-custom'>
                        <div className='cta-left-info mb-2'>
                            <div className="feature-img-shape d-inline-block rounded-circle mb-32">
                                <img src="/assets/logos/helthjem.svg" alt="shape"
                                     style={{width: '150px'}}/>
                            </div>
                            <h4>Helthjem</h4>
                            <br/>
                            <p className="mb-0">
                                Levert hjem (0-1KG): <b>55,-</b>
                            </p>
                            <p className="mb-0">
                                Levert hjem (1-2KG): <b>62,-</b>
                            </p>
                            <p className="mb-0">
                                Levert hjem (2-5KG): <b>69,-</b>
                            </p>
                            <p className="mb-0">
                                Hentested (0-2KG): <b>67,-</b>
                            </p>
                            <p className="mb-0">
                                Hentested (2-5KG): <b>74,-</b>
                            </p>
                            <p className="mb-0">
                                Hentested (5-10KG): <b>87,-</b>
                            </p>
                            <hr/>
                            <p>
                                Prisene inkludererer alle avgifter, eks. mva.
                                <br/>
                                For full prisliste, vennligst
                                <a
                                    href="/assets/files/prisliste_helthjem.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {" "} se her.
                                </a>
                            </p>
                        </div>
                        <div className='action-btns mt-auto'>
                            <a
                                href="https://app.dropper.no/authentication/register"
                                target="_blank"
                                rel="noreferrer"
                                className='btn btn-outline-primary btn-sm'
                            >
                                Kom i gang
                            </a>
                        </div>
                        <div className='cta-img position-absolute right-0 bottom-0'>
                            <img
                                src='assets/icons/undraw_maker_launch.svg'
                                alt='cta'
                                className='img-fluid'
                            />
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 mt-4'>
                    <div
                        className='position-relative d-flex flex-column h-100 flex-wrap bg-success-soft p-5 rounded-custom'>
                        <div className='cta-left-info mb-2'>
                            <div className="feature-img-shape d-inline-block rounded-circle mb-32">
                                <img src="/assets/logos/carriers/bring-logo.png" alt="shape" style={{width: '100px'}}/>
                            </div>
                            <h4>Bring / Posten</h4>
                            <br/>
                            <p className="mb-0">
                                Pakke i postkassen: <b>39,-</b>
                            </p>
                            <p className="mb-0">
                                Pakke levert til hentested (&lt;3kg): <b>119,-</b>
                            </p>
                            <p className="mb-0">
                                Pakke levert hjem (&lt;3kg): <b>139,-</b>
                            </p>
                            <hr/>
                            <p>
                                Prisene inkluderer alle avgifter, eks. mva.
                                <br/>
                                For full prisliste, vennligst <a href="#">se her.</a>
                            </p>
                        </div>
                        <div className='action-btns mt-auto'>
                            <a
                                href="https://app.dropper.no/authentication/register"
                                target="_blank"
                                rel="noreferrer"
                                className='btn btn-outline-primary btn-sm'
                            >
                                Kom i gang
                            </a>
                        </div>
                        <div className='cta-img position-absolute right-0 bottom-0'>
                            <img
                                src='assets/icons/undraw_letter_green.svg'
                                alt='cta'
                                className='img-fluid'
                            />
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 mt-4'>
                    <div
                        className='position-relative d-flex flex-column h-100 flex-wrap bg-info-soft p-5 rounded-custom'>
                        <div className='cta-left-info mb-2'>
                            <div className="feature-img-shape d-inline-block rounded-circle mb-32">
                                <img src="/assets/logos/carriers/postnord-logo.png" alt="shape"
                                     style={{width: '100px'}}/>
                            </div>
                            <h4>PostNord</h4>
                            <br/>
                            <p className="mb-0">
                                Pakke levert til hentested (&lt;3kg): <b>89,-</b>
                            </p>
                            <p className="mb-0">
                                Pakke levert hjem u/signatur (&lt;3kg): <b>79,-</b>
                            </p>
                            <p className="mb-0">
                                Pakke levert til bedrift (&lt;3kg): <b>69,-</b>
                            </p>
                            <hr/>
                            <p>
                                Prisene inkluderer alle avgifter, eks. mva.
                                <br/>
                                For full prisliste, vennligst
                                <a
                                    href="/assets/files/prisliste_postnord.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {" "} se her.
                                </a>
                            </p>
                        </div>
                        <div className='action-btns mt-auto'>
                            <a
                                href="https://app.dropper.no/authentication/register"
                                target="_blank"
                                rel="noreferrer"
                                className='btn btn-outline-primary btn-sm'
                            >
                                Kom i gang
                            </a>
                        </div>
                        <div className='cta-img position-absolute right-0 bottom-0'>
                            <img
                                src='assets/icons/undraw_letter.svg'
                                alt='cta'
                                className='img-fluid'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);


export default FeatureShippingRates;
