import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ComingSoonScreen from '../components/others/ComingSoonScreen';
import Layout from '../layout/Layout';

const CommingSoon = () => {
    return (
        <Layout>
            <PageMeta title='Coming Soon- Software &amp; IT Solutions HTML Template'/>
            <ComingSoonScreen/>
        </Layout>
    );
};

export default CommingSoon;
