import React from 'react';
import PageMeta from '../components/common/PageMeta';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const Privacy = () => {
    return (<Layout>
        <PageMeta title='Dropper - Personvern - Fraktløsninger for bedrifter'/>
        <Navbar classOption='navbar-light'/>
        <div
            className="bg-white  mt-10 pt-10 container"
            style={{
                minHeight: "50vh"
            }}
        >
            <div className="text-center">
                <h1 className="h1 mt-100 fw-semi-bold">Personvern-policy</h1>
            </div>

            {/* Innledning Start*/}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">Innledning</h3>
                <p className="lead">
                    Takk for at du besøker Dropper og går gjennom vår personvern-policy!
                    <br/>
                    <br/>
                    Vi har gjort et forsøk på å være så tydelig som mulig angående vår policy rundt personvern. Dersom
                    du skulle ha noen spørsmål angående personvern, kan du alltid ta kontakt med oss på
                    privacy@dropper.no
                </p>
            </div>
            {/* Innledning Slutt*/}

            {/* 1. Generelt start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">1. Generelt</h3>
                <p className="lead">
                    Hos Dropper tar vi ditt personvern meget sterkt, og har tatt mange grep for å styrke og sikre ditt
                    personvern mens du bruker våre nettsider- og tjenester. En av tiltaksmetodene våre har vært å
                    gå bort fra de standard-verktøyene som brukes på de fleste nettsider i dag, for eksempel innenfor
                    statistikk, analyser og ads.
                </p>

                <p className="lead">
                    Vi har tatt bevisste valg om hvilke data-leverandører vi har benyttet oss av, i et forsøk på å
                    styrke
                    personvern og sikkerhet. Dersom du skulle ønske å se hvilke data-leverandører vi benytter oss av,
                    kan
                    du se listen vår lenger nede.
                </p>

                <p className="lead">
                    Denne personvern-policyen inneholder detaljer om hvilke data vi innhenter fra deg som bruker,
                    hvordan
                    disse dataene lagres, hvordan de behandles, hvordan de deles, og hva vi gjør for å sikre dataene.
                </p>
            </div>
            {/* 1. Generelt slutt */}

            {/* 2. Kontakt-punkt */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">2. Kontakt-punkt: sikkerhet og personvern</h3>
                <p className="lead">
                    Dersom du har spørsmål, bekymringer eller annen informasjom du ønsker å informere oss om som
                    omhandler
                    sikkerhet, personvern eller lignende, kan du ta kontakt med oss på følgende kommunkasjons-metoder:
                </p>

                <p className="lead">
                    <ul
                        style={{
                            marginLeft: "40px"
                        }}
                    >
                        <li>E-post (personvern): <b>privacy@dropper.no</b></li>
                        <li>E-post (sikkerhet): <b>security@dropper.no</b></li>
                    </ul>
                </p>
            </div>
            {/* 2. Kontakt-punkt slutt */}

            {/* 3. Innhenting av data */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">3. Data vi henter inn</h3>
                <p className="lead">
                    Dropper henter inn en større mengde informasjon av personlig informasjon; både fra våre
                    fokus-brukere (bedriftskunder m.m.), i tillegg til opplysninger om slutt-kunder.
                    <br/>
                    Her er noe av dataene vi samler inn (i prioritert rekkefølge):

                    <br/>
                    <br/>
                    <b>Informasjon som du gir til oss direkte, inkluderer:</b>
                    <ul
                        style={{
                            marginLeft: 20
                        }}
                    >
                        <li>E-post adresse</li>
                        <li>Telefonnummer</li>
                        <li>Fullt navn</li>
                        <li>Brukernavn</li>
                        <li>Passord (kryptert / hashet)</li>
                        <li>Profilbilde(r)</li>
                        <li>Informasjon om bedriften din, og opplysninger om slutt-kunder</li>
                        <li>Innstillinger du setter for bruker-opplevelse</li>
                    </ul>
                    <hr/>
                    <br/>
                    <b>Informasjon vi henter inn mens du bruker våre tjenester:</b>
                    <ul
                        style={{
                            marginLeft: 20
                        }}
                    >
                        <li>Enhetsinformasjon, IP-adresse(r)</li>
                        <li style={{marginLeft: 20}}>
                            <i>
                                Herunder: standard-språk, OS, minne, batteri-nivå osv, skjerm-størrelse, versjon av
                                nettleser, osv.
                            </i>
                        </li>
                        <li>Handlinger som du utfører på våre tjenester</li>
                        <li style={{marginLeft: 20}}>
                            <i>
                                Hvilke sider du besøker, hvor lenge, når, fra hvor, hva du gjør på
                                siden
                            </i>
                        </li>
                        <li>Finans-historikk / betaling</li>
                        <li style={{marginLeft: 20}}>
                            <i>
                                Type betalingsmetode, beløp, tidspunkt, abonnement, o.l.
                            </i>
                        </li>
                        <li>Kommunikasjon</li>
                        <li style={{marginLeft: 20}}>
                            <i>
                                Chat med oss, ved e-post, utfyllelse av kontakt-skjema o.l.
                            </i>
                        </li>
                        <li>Søks-historikk</li>
                        <li style={{marginLeft: 20}}>
                            <i>
                                Ting du søker på gjennom vår plattform kan bli logget i våre
                                systemer
                            </i>
                        </li>
                        <li>Cookies</li>
                        <li style={{marginLeft: 20}}>
                            <i>
                                Vi benytter oss av cookies når du besøker våre nettsider, <a href="/cookies">trykk her
                                for å lære mer</a>
                            </i>
                        </li>

                    </ul>
                    <hr/>
                    <br/>
                    <b>Informasjon vi får av tredje-parter:</b>
                    <ul
                        style={{
                            marginLeft: 20
                        }}
                    >
                        <li>Informasjon om bestillinger</li>
                        <li style={{marginLeft: 20}}>
                            <i>
                                Herunder: gjenstander kjøpt, kunde-opplysninger, betalingsinformasjon (betalingsmetode,
                                type, beløp), frakt, historikk på bestilling
                            </i>
                        </li>
                        <li>Informasjon om kunder</li>
                        <li style={{marginLeft: 20}}>
                            <i>
                                Opplysninger om e-post adresser, interne notat på kunde-profil, adresse, utdypende
                                kontakt-opplysninger, mm.
                            </i>
                        </li>
                    </ul>
                </p>
                <hr/>
                <p className="lead">
                    Vær obs på listene oppgitt ovenfor ikke er en uttømmende liste på all informasjon vi henter ut, men
                    er ment som eksempler på viktige data. Data som er i naturlig tilknytning men som
                    ikke er listet ovenfor er naturlig å anta er inkludert.
                </p>
            </div>
            {/* 3. Dine forpliktelser slutt */}

            {/* 4. Informasjonen brukes til start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">4. Anvendelse av informasjonen</h3>
                <p className="lead">
                    Data som blir hentet ut av våre systemer er ikke alltid like lett å forklare i alle scenario hvordan
                    de benyttes, men vi har prøvd å fokusere på viktige poeng når dine data benyttes, slik at du får
                    en bedre forståelse på hvordan dine data blir håndtert.
                </p>

                <br/>
                <h4 className="h4 mt-20">4.1 Nødvendig funksjonalitet</h4>
                <p className="lead">
                    I all hovedsak henter vi inn data for å levere produktene og tjenestene rundt Dropper. Vi bruker
                    også denne informasjonen for å forbedre oss i fremtiden, slik at du får en bedre brukeropplevelse på
                    Dropper. For eksempel bruker vi informasjonen til å vise deg relevante handlinger, og å gi en
                    oversikt
                    på en viss situasjon.
                </p>
                <h4 className="h4 mt-20">4.2 Sikkerhet</h4>
                <p className="lead">
                    Vi bruker informasjonen som er innhentet til å beskytte oss fra onde aktører, og generelt beskytte
                    våre produkter, tjenester og våre kunder. Dette inkluderer det å verifisere din identitet for å
                    verifisere
                    kontoen din, for å nedkjempe ulovlig aktivitet.

                    <br/>
                    <br/>
                    Vi bruker også denne informasjonen til å analysere og undersøke visse hendelser nærmere dersom
                    det skulle antyde til risiko for sikkerheten for vår plattform.
                </p>
                <h4 className="h4 mt-20">4.3 Undersøkelser, analyser og forbedringer</h4>
                <p className="lead">
                    Informasjonen vi har innhentet blir også benyttet til videre analyser for å sørge for at tjenestene
                    våre yter som forventet, og for å identifisere eventuelle problem som skulle med våre produkter
                    eller tjenester.
                </p>
                <h4 className="h4 mt-20">4.4 Kommunikasjon med deg rundt våre tjenester</h4>
                <p className="lead">
                    Vi bruker også informasjonen til å kommunisere med deg angående våre produkter og tjenester, for
                    eksempel
                    ved oppdateringer, og eventuelle endringer til våre vilkår. Dersom du har valgfritt meldt deg inn
                    bruker vi også informasjonen til å sende deg markedsføring.
                </p>
            </div>
            {/* 4. Verneting og lovvalg slutt */}

            {/* 5. Tidsperspektiv start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">5. Tidsperspektiv</h3>
                <p className="lead">
                    Avhengig av hvilken data som er lagret kan varigheten på dataen som er lagret vare fra noen dager
                    til flere år.
                    For eksempel, generelle data som statistikk og analyser har kort varighet, da disse brukes til
                    feilsøking
                    og forbedringer, og har ingen andre reelle behov for langtidslagring.

                    <ul>
                        <li>
                            Lagring av data relatert til statistikk/analyser lagres som oftest i korte tidsintervall.
                            For
                            eksempel er data om når du besøker våre nettsider og hva du gjør slettet etter rundt 4 uker.
                        </li>
                        <li>
                            Data relatert til din konto vil beholdes så lenge din konto er aktiv hos oss. For eksempel,
                            om du har et løpende abonnement hos oss, eller har en aktiv kontrakt med oss vil dataene
                            relatert til din konto opprettholdes på ubestemt tid. Etter terminering av samarbeid vil
                            dataene automatisk slettes innen 5 år.
                        </li>
                    </ul>
                </p>
            </div>
            {/* 5. Tidsperspektiv slutt */}

            {/* 6. Tidsperspektiv start */}
            <div className="text-start mt-60">
                <h3 className="h3 text-success">6. Hvordan vi sikrer din informasjon</h3>
                <p className="lead">
                    Vi tar sikkerhet og personvern alvorlig hos Dropper, og har gjennomført flere aktive steg for å
                    sørge for at ingen data kommer på avveie eller blir delt uten ditt samtykke. I alle tilfeller har vi
                    implementert kryptering av all data som lagres, og av all data som er under "flytting" mellom våre
                    tjenester.

                    <br/>
                    <br/>
                    Internt har vi også strenge sikkerhets-regler for å sørge for at bare riktig personale har tilgang
                    til data, og bare til data som er absolutt nødvendig. Alle våre ansatte må gjennom flere
                    autentiserings- og autorisasjonssteg for å kunne behandle (eller få tilgang til,) data hos Dropper.
                    Vi har automatiske prosesser og analyse-verktøy for å analysere innbrudd på system og bruker bare
                    pålitelige leverandører for å levere våre produkter og tjenester. En full oversikt over våre
                    leverandører kan du finne på <a href="/dpa">DPA (Data Processing Amendmum)</a>
                    <br/>
                    <br/>
                    <b>Obs</b>: Vi selger <u>aldri</u> dine data.
                </p>
            </div>

            <div className="text-start mt-60">
                <h3 className="h3 text-success">7. Henvendelser angående GDPR og personvern</h3>
                <p className="lead">
                    Som et selskap under EU sine GDPR-lover forplikter vi oss til å etterfylle alle krav og plikter
                    rundt GDPR. Et av punktene i GDPR er at du når som helst har mulighet til å få tilgang til- eller få
                    slettet data som vi lagrer av deg som privatperson.

                    <br/>
                    <br/>
                    Dersom det er i din interesse å utøve noen av dine lovfestede rettigheter kan du alltids ta kontakt
                    med oss på privacy@dropper.no. Alle henvendelser angående personvern besvares innen 72 timer. På
                    grunn av hensyn til personvern må vi verifisere din identitet før vi kan hjelpe deg, dette for å
                    sørge for at ingen får uautorisert tilgang til vernet data.
                </p>
            </div>

            <div className="text-start mt-60">
                <h3 className="h3 text-success">8. Oppdatering av vårt personvern</h3>
                <p className="lead">
                    Vi kan oppdatere denne personvernerklæringen fra tid til annen for å reflektere endringer i våre
                    praksiser eller av andre drifts-, juridiske eller reguleringsmessige årsaker. Vi vil varsle deg om
                    eventuelle vesentlige endringer i denne personvernerklæringen ved å poste den oppdaterte erklæringen
                    på vår nettside og, om nødvendig, ved å sende en e-postvarsling.

                    <br/>
                    <br/>
                    Vi oppfordrer deg til å gjennomgå
                    denne personvernerklæringen jevnlig for å holde deg informert om hvordan vi beskytter din personlige
                    informasjon.
                </p>
            </div>

            <div className="text-start mt-60">
                <h3 className="h3 text-success">9. Bruk av cookies / informasjonskapsler</h3>
                <p className="lead">
                    Vi bruker essensielle cookies på våre nettsider og tjenester for å identifisere deg med en bruker-
                    konto på våre systemer. Vi tillater også enkelte av leverandørene våre å sette inn andre cookies
                    for å hjelpe oss med å sikre nettsiden vår mot angrep.

                    <br/>
                    Tredjeparts-cookies brukes ikke til markedsføring, og blir ikke solgt. Disse blir bare benyttet
                    i formålet med å forbedre din opplevelse på nettsiden, i tillegg til å besktyyte våre tjenester.
                </p>
            </div>
        </div>
        <FooterOne footerLight/>
    </Layout>);
};

export default Privacy;
