import React from "react";
import {Link} from "react-router-dom";

const OffCanvasMenu = () => {
    return (
        <>
            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasWithBackdrop"
            >
                <div className="offcanvas-header d-flex align-items-center mt-4">
                    <Link
                        to="/"
                        className="d-flex align-items-center mb-md-0 text-decoration-none"
                    >
                        <img
                            src="https://dropper.no/assets/logos/dropper-logo/PNGSVG/1x/Asset1.png"
                            alt="logo"
                            className="img-fluid logo-color"
                            style={{
                                width: "50%"
                            }}
                        />
                    </Link>
                    <button
                        type="button"
                        className="close-btn text-danger"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    >
                        <i className="far fa-close"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                        <li className="nav-item dropdown">
                            <Link
                                className="nav-link"
                                to="/"
                            >
                                Hjem
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/priser"
                                className="nav-link"
                            >
                                Priser
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/egenskaper"
                                className="nav-link"
                            >
                                Våre tjenester
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                href="/#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Annet
                            </a>
                            <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                                <div className="dropdown-grid rounded-custom width-full">
                                    <div className="dropdown-grid-item">
                                        <h6 className="drop-heading">Nyttige linker</h6>
                                        <Link to="/kontakt-oss" className="dropdown-link px-0">
                          <span className="me-2">
                            <i className="far fa-address-book"></i>
                          </span>
                                            <div className="drop-title">Kontakt Oss</div>
                                        </Link>
                                        <Link
                                            to="/integrasjoner"
                                            className="dropdown-link px-0"
                                        >
                          <span className="me-2">
                            <i className="far fa-server"></i>
                          </span>
                                            <div className="drop-title">Våre integrasjoner</div>
                                        </Link>
                                        <Link to="/blogg" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-th-large"></i>
                          </span>
                                            <div className="drop-title">Blogg</div>
                                        </Link>
                                    </div>
                                    <div className="dropdown-grid-item radius-right-side bg-light">
                                        <h6 className="drop-heading">Ressurser </h6>

                                        <Link to="https://help.dropper.no/" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-life-ring"></i>
                          </span>
                                            <div className="drop-title">Hjelpesenter</div>
                                        </Link>
                                        <Link to="/request-for-demo" className="dropdown-link disabled">
                          <span className="me-2">
                            <i className="far fa-laptop-code"></i>
                          </span>
                                            <div className="drop-title">API Dokumentasjon</div>
                                        </Link>
                                        <Link to="/sikkerhet" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-shield"></i>
                          </span>
                                            <div className="drop-title">Sikkerhet</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="action-btns mt-4 ps-3">
                        <Link
                            href="https://app.dropper.no/authentication/login"
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-outline-primary me-2"
                        >
                            Logg inn
                        </Link>
                        <a
                            href="https://app.dropper.no/authentication/register"
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-primary"
                        >
                            Kom i gang

                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OffCanvasMenu;
