const IconBoxData = [
    {
        id: 1,
        classOption: 'bg-primary',
        icon: 'fal fa-layer-group fa-2x text-white',
        title: 'Good Performance',
        description:
            'Appropriately grow competitive leadership rather than strategic technically sound processes without state .',
    },
    {
        id: 2,
        classOption: 'bg-danger',
        icon: 'fal fa-shield-check fa-2x text-white',
        title: 'Highly Secure',
        description:
            'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
    },
    {
        id: 3,
        classOption: 'bg-dark',
        icon: 'fal fa-code fa-2x text-white',
        title: 'Fast Development',
        description:
            'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
    },
];
const FaqOneData = [
    {
        id: 1,
        faqTitle: 'Koster det penger å bruke Dropper?',
        faqDesc:
            `Dropper er en betalt tjeneste, men vi tilbyr selvsagt 14-dagers gratis & uforpliktende prøveperiode. Vi vet at Dropper er et av de raskeste systemene på markedet, men en slik ytelse fører også til større operasjonskostnader. Dette er et bevisst valg, da vi mener at den tiden man sparer ved å bruke et raskere system utgjør mer enn den ene kronen man betaler per ordre.`,
    },
    {
        id: 2,
        faqTitle: 'Kan jeg gi beskjed om det er noe jeg trenger?',
        faqDesc:
            `Ja, selvsagt! Vi ønsker å tilpasse oss til de aller fleste kunder som driver med salg på nett, og dersom det er egenskaper du trenger trenger, så anbefaler vi deg på det sterkeste å gi oss en lyd om dette slik at vi kan forbedre oss!`,
    },
    {
        id: 3,
        faqTitle: 'Kan jeg bruke mine egne avtaler?',
        faqDesc:
            'Til å begynne med er dette noe vi arbeider med å få til, men i mellomtiden må man bruke våre (ganske gunstige) fraktavtaler. Husk at du har muligheter til å integrere med vårt API-system slik at du kan dekke dine spesielle behov inntil videre!',
    },
];

const TestimonialData = [
    {
        id: 1,
        authorImg: 'assets/img/testimonial/1.jpg',
        authorName: 'Mr.Rupan Oberoi',
        authorTitle: 'Founder and CEO at Amaara Herbs',
        quoteTitle: 'The Best Template You Got to Have it!',
        authorQuote:
            'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
    },
    {
        id: 2,
        authorImg: 'assets/img/testimonial/2.jpg',
        authorName: 'Joan Dho',
        authorTitle: 'Founder and CTO',
        quoteTitle: 'Best Template for SAAS Company!',
        authorQuote:
            'Dynamically create innovative core competencies with effective best practices promote innovative infrastructures.',
    },
    {
        id: 3,
        authorImg: 'assets/img/testimonial/3.jpg',
        authorName: 'Ranu Mondal',
        authorTitle: 'Lead Developer',
        quoteTitle: 'It is undeniably good!',
        authorQuote:
            'Rapidiously supply client-centric e-markets and maintainable processes progressively engineer',
    },
    {
        id: 4,
        authorImg: 'assets/img/testimonial/4.jpg',
        authorName: 'Mr.Rupan Oberoi',
        authorTitle: 'Founder and CEO at Amaara Herbs',
        quoteTitle: 'The Best Template You Got to Have it!',
        authorQuote:
            'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
    },
];

const TestimonialOneData = [
    {
        id: '#testimonial-tab-1',
        activeClass: 'active',
        title: 'The Best Template You Got to Have it!',
        desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
        authorName: 'Joe Richard',
        authorDesn: 'Visual Designer',
        authorThumb: 'assets/img/testimonial/t-1.jpg',
        authorAvatar: 'assets/img/testimonial/1.jpg',
    },
    {
        id: '#testimonial-tab-2',
        title: 'Embarrassed by the First Version.',
        desc: 'Energistically streamline robust architectures whereas distributed mindshare. Intrinsicly leveraother backend growth strategies through 24/365 products. Conveniently pursue revolutionary communities for compelling process improvements.',
        authorName: 'Rupan Oberoi',
        authorDesn: 'Web Designer',
        authorThumb: 'assets/img/testimonial/t-2.jpg',
        authorAvatar: 'assets/img/testimonial/2.jpg',
    },
    {
        id: '#testimonial-tab-3',
        title: 'The Best Template You Got to Have it!',
        desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
        authorName: 'Joe Richard',
        authorDesn: 'Visual Designer',
        authorThumb: 'assets/img/testimonial/t-3.jpg',
        authorAvatar: 'assets/img/testimonial/3.jpg',
    },
    {
        id: '#testimonial-tab-4',
        title: 'The Best Template You Got to Have it!',
        desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
        authorName: 'Joe Richard',
        authorDesn: 'Visual Designer',
        authorThumb: 'assets/img/testimonial/t-4.jpg',
        authorAvatar: 'assets/img/testimonial/4.jpg',
    },
    {
        id: '#testimonial-tab-5',
        title: 'The Best Template You Got to Have it!',
        desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
        authorName: 'Joe Richard',
        authorDesn: 'Visual Designer',
        authorThumb: 'assets/img/testimonial/t-5.jpg',
        authorAvatar: 'assets/img/testimonial/5.jpg',
    },
];

export {IconBoxData, FaqOneData, TestimonialData, TestimonialOneData};
